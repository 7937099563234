import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Image",
	component: "LinkableImage",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "File",
					type: "ImageField",
					key: "file",
				},
				{
					title: "Title",
					type: "TextField",
					key: "title",
				},
				{
					title: "Alternative Text",
					type: "TextField",
					key: "alt",
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
				},
				{
					title: "Link image to URL",
					type: "UrlField",
					advanced: true,
					key: "url",
				},
			],
		},
	],

	default: {
		component: "LinkableImage",
	},

	thumbnails: {},
});
