import pt_PT from "@translations/pt_PT";
import { deepClone } from "@translations/utils";

const ipamPT = deepClone(pt_PT);

ipamPT.form.header.req = "Pedido de Informações";
ipamPT.form.header.reqSub =
	"Preencha o formulário com os seus dados para solicitar informações";
ipamPT.form.header.requestLong =
	"Preencha o formulário com os seus dados para solicitar informações";
ipamPT.form.header.subtitle =
	"Deixe-nos os seus dados e entraremos em contacto consigo brevemente";
export default ipamPT;
