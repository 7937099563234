export default {
	STUDY_LANDING_TYPE: {
		title: "Study Type Landing ",
		dataPacks: ["STUDY_LANDING"],
		local: true,
		taxonomy: false,
		fromPage: false,
		translate: false,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
