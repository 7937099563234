export default {
	INFO_LANDINGS: {
		title: "Info Landings",
		dataPacks: ["LANDINGS", "DISCOUNTS", "MODALS"],
		local: true,
		taxonomy: false,
		fromPage: true,
		translate: true,
		schema: {
			templates: ["LandingInfoPortugal"],
			fields: [
				{
					title: "Tipología",
					type: "AsyncSelect",
					key: "typology",
					source: "LANDING_TYPE",
					showList: true,
					from: "typology",
					indexable: true,
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
