import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Horizontal Tab Element",
	component: "HorizontalTabElement",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Tab Title",
					type: "TextField",
					key: "title",
					mandatory: true,
					hideable: false,
					humanReadable: true,
				},
				{
					title: "Component",
					type: "ComponentArray",
					hideable: true,
					mandatory: true,
					whiteList: ["BasicContent", "Table"],
					key: "componentModules",
				},
			],
		},
	],
	default: {
		component: "HorizontalTabElement",
		title: "Lorem ipsum",
		componentModules: [
			{
				component: "Table",
				tabHeaderFirst: "Lorem Ipsum",
				tabHeaderSecond: "Lorem Ipsum",
				elements: [
					{
						component: "TableRow",
						title: "Lorem ipsum",
						credits: "0",
						fileName: "pdf",
						file: "",
					},
					{
						component: "TableRow",
						title: "Lorem ipsum",
						credits: "0",
						fileName: "pdf",
						file: "",
					},
					{
						component: "TableRow",
						title: "Lorem ipsum",
						credits: "0",
						fileName: "pdf",
						file: "",
					},
				],
			},
		],
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HorizontalTabElement@2x`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HorizontalTabElement@2x`,
	},
});
