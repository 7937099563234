import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Blog Categories Menu",
	component: "BlogCategoriesMenu",
	dataPacks: ["BLOG"],
	category: "articlesAndEvents",
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Nota",
					key: "noteGeneral",
					type: "NoteField",
				},
				{
					title: "Categories",
					type: "ReferenceField",
					sources: [{ structuredData: "BLOG_CATEGORY" }],
					key: "data",
				},
			],
		},
	],

	default: {
		component: "BlogCategoriesMenu",
		data: {
			mode: "auto",
			sources: [{ structuredData: "BLOG_CATEGORY" }],
			order: "alpha",
			quantity: 0,
		},
		noteGeneral: {
			title: "No modificar",
			text: "Este distribuidor es necesario para obtener los datos de las categorías, pero no hay que modificarlo. Si no aparecen todas las categorías en el menú es porque no tienen enlaces en el dato estructurado o porque no hay entradas asociadas.",
		},
		hasDistributorData: true,
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/BlogCategoriesMenu_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/BlogCategoriesMenu_x2.png`,
	},
});
