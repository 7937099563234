const TypologySelect = {
	content: {
		title: "Tipología",
		type: "Select",
		key: "typology",
		mandatory: true,
		options: [
			{ value: "typology", label: "Generic Info Landing" },
			{ value: "ProductLanding", label: "Product Landing" },
			{ value: "DualProductLanding", label: "Dual Product Landing" },
			{ value: "AreaLanding", label: "Area Landing" },
			{ value: "DualAreaLanding", label: "Dual Area Landing" },
			{ value: "OtherInfoLanding", label: "Other Info Landing" },
		],
	},
	typology: "typology",
};

export default TypologySelect;
