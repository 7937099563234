import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Icon Card",
	component: "IconCard",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					mandatory: false,
					hideable: true,
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
				},
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Description",
					type: "RichText",
					key: "description",
					hideable: true,
					helptext: "Max. 6 lines in mobile.",
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "IconCard",
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
		},
		veil: 0,
		title: "Lorem ipsum",
		description: "Lorem ipsum dolor sit amet",
	},
});
