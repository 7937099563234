import logoempty from "@axthumbs/options/multibrandheader_logoempty.png";
import logoiade from "@axthumbs/options/multibrandheader_logoiade.png";
import logoiadees from "@axthumbs/options/multibrandheader_logoiadees.png";
import logoipam from "@axthumbs/options/multibrandheader_logoipam.png";
import logoue from "@axthumbs/options/multibrandheader_logoue.png";
import thumbnail1x from "@axthumbs/thumbnails/multibrandheader.png";
import thumbnail2x from "@axthumbs/thumbnails/multibrandheader_x2.png";
import { instance } from "@instance-config";
import { createSchema } from "@utils/index";

const isIADEES = instance.includes("iadeES");

const logosSelector = isIADEES
	? [
			{
				value: "ue",
				img: logoue,
			},
			{
				value: "iadees",
				img: logoiadees,
			},
			{
				value: "none",
				img: logoempty,
			},
	  ]
	: [
			{
				value: "ue",
				img: logoue,
			},
			{
				value: "iade",
				img: logoiade,
			},
			{
				value: "ipam",
				img: logoipam,
			},
			{
				value: "none",
				img: logoempty,
			},
	  ];

export default createSchema({
	schemaType: "module",
	displayName: "MultiBrandHeader",
	component: "MultiBrandHeader",
	type: "header",
	category: "header",
	dataPacks: ["LANDINGS"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					humanReadable: true,
				},

				{
					key: "logosDivider",
					type: "FieldsDivider",
					data: {
						title: "Logos in header",
					},
				},
				{
					title: "Position 1",
					key: "logo1",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: logosSelector,
					columns: 5,
				},
				{
					title: "Position 2",
					key: "logo2",
					type: "VisualUniqueSelection",
					mandatory: false,
					hideable: true,
					options: logosSelector,
					columns: 5,
				},
				...(!isIADEES
					? [
							{
								title: "Position 3",
								key: "logo3",
								type: "VisualUniqueSelection",
								mandatory: false,
								hideable: true,
								options: logosSelector,
								columns: 5,
							},
					  ]
					: []),

				{
					title: "WhatsApp Number",
					type: "TextField",
					key: "whatsappNumber",
					hideable: true,
					helptext: `Examples:\n
            Presencial "+35 1969 704 048", online "+35 1967 044 738".`,
				},
				{
					title: "Phone Numbers List",
					type: "ComponentContainer",
					whiteList: ["PhoneNumbersList"],
					key: "phoneNumbersList",
					mandatory: true,
				},
				{
					title: "Button text",
					type: "TextField",
					key: "formButtonText",
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "MultiBrandHeader",
		type: "header",
		title: "Multi Brand Header",
		logo1: "ue",
		logo2: "iade",
		logo3: "ipam",
		phoneNumbersList: {
			component: "PhoneNumbersList",
			title: "Phone Numbers List",
			elements: [
				{
					component: "PhoneNumberElement",
					title: "Presencial:",
					phone: "000 000 000",
				},
				{
					component: "PhoneNumberElement",
					title: "Online:",
					phone: "000 000 000",
				},
			],
		},
		whatsappNumber: "+35 123 456 789",
		formButtonText: "Solicitar informação",
		setAsDefault: false,
	},
	thumbnails: {
		"1x": thumbnail1x,
		"2x": thumbnail2x,
	},
});
