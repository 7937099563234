export default {
	AWARDS: {
		title: "Awards",
		dataPacks: ["AWARDS"],
		local: false,
		taxonomy: false,
		fromPage: true,
		translate: true,
		schema: {
			templates: ["AwardDetail"],
			fields: [
				{
					title: "Award Title",
					type: "HeadingField",
					humanReadable: true,
					key: "awardTitle",
					from: "awardTitle",
				},
			],
			searchFrom: [],
		},
		clone: null,
		defaultValues: null,
	},
};
