import { moduleCategories } from "@constants/moduleCategories";

import { instance } from "./instances/current.json";
import griddoJSON from "./src/griddo.json";
import dataPacks from "./src/models/data-packs/dataPacks";
import dataPacksCategories from "./src/models/data-packs/dataPacksCategories";
import languages from "./src/models/data-packs/languages";
import structuredData from "./src/models/data-packs/structuredData";
import structuredDataTaxonomies from "./src/models/data-packs/structuredDataTaxonomies";
import components from "./src/models/schemas/components";
import menuItems from "./src/models/schemas/config/menu-items";
import menus from "./src/models/schemas/menus";
import modules from "./src/models/schemas/modules";
import templates from "./src/models/schemas/templates";

const themes = griddoJSON.themes
	.filter((theme) => instance.includes(theme.instance))
	.map((theme) => ({
		label: theme.name,
		value: theme.id,
		default: !!theme.default,
	}));

const schemas = {
	all: { ...components, ...modules },
	modules,
	templates,
	dataPacks,
	dataPacksCategories,
	structuredData: { ...structuredData, ...structuredDataTaxonomies },
	languages,
	menus,
	menuItems,
};

export { schemas, themes, moduleCategories };
