const StudyLandingDistributor = {
	content: {
		title: "Study",
		type: "ReferenceField",
		sources: [
			{ structuredData: "STUDY" },
			{ structuredData: "EXTERNAL_STUDY" },
		],
		key: "data",
	},
	default: {
		mode: "auto",
		sources: [
			{ structuredData: "STUDY" },
			{ structuredData: "EXTERNAL_STUDY" },
		],
		order: "alpha",
		allLanguages: true,
		preferenceLanguage: true,
		quantity: 0,
	},
};

export default StudyLandingDistributor;
