import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Awards Card",
	component: "AwardsCard",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Ranking",
					type: "RichText",
					key: "ranking",
					mandatory: true,
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Subtitle",
					type: "RichText",
					key: "subtitle",
					mandatory: true,
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Date",
					type: "RichText",
					key: "date",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Students",
					type: "RichText",
					key: "students",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Description",
					type: "RichText",
					key: "description",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "DateTitle (Only visible in Layout3)",
					type: "RichText",
					key: "dateTitle",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "StudentsTitle (Only visible in Layout3)",
					type: "RichText",
					key: "studentsTitle",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "DescriptionTitle (Only visible in Layout3)",
					type: "RichText",
					key: "descriptionTitle",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "AwardsCard",
		ranking: "Nº 1 ranking El Mundo y Expansión",
		subtitle:
			"Máster Universitario en Práctica Jurídica Especialidad en Derecho Internacional de los Negocios",
		dateTitle: "",
		date: "Convocatorias: Febrero y Octubre 2021",
		studentsTitle: "",
		students:
			"Dirigido a Graduados o Licenciados en Derecho o Título equivalente",
		descriptionTitle: "",
		description:
			"Formación integral para el ejercicio de la profesión de abogado.",
		button: {
			component: "Button",
			buttonText: "Link",
			appearance: "secondary",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/AwardsCard@2x`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/AwardsCard@2x`,
	},
});
