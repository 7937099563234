import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Small Card",
	component: "SmallCard",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					humanReadable: true,
				},
				{
					title: "Description",
					type: "RichText",
					key: "description",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "File",
					type: "ImageField",
					key: "file",
					helptext: "Recommended minimum image size: 904×1088",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "SmallCard",
		title: "Lorem ipsum",
		description:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
		file: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			url: `${CLOUDINARY_BASE_UPLOAD_URL}/CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e`,
		},
	},

	thumbnails: {},
});
