import thumbnail from "@axthumbs/modules/FormTypeDualProductLanding.png";
import { createSchema } from "@utils/";

export default createSchema({
	schemaType: "module",
	displayName: "Multi Product",
	component: "FormTypeMultiProduct",
	category: "FormType",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Study",
					type: "ReferenceField",
					sources: [{ structuredData: "STUDY" }],
					key: "data",
				},
			],
		},
	],
	default: {
		title: "FormTypeMultiProduct",
		component: "FormTypeMultiProduct",
		data: {
			mode: "auto",
			sources: [{ structuredData: "STUDY" }],
			order: "alpha",
			quantity: 15,
		},
		hasDistributorData: true,
	},
	thumbnails: {
		"1x": thumbnail,
		"2x": thumbnail,
	},
});
