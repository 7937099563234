import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Related Programs Element",
	component: "RelatedProgramsElement",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					helptext: "Recommended minimum image size: 2508x1030",
					mandatory: true,
				},
				{
					title: "Title",
					type: "TextField",
					key: "title",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Date",
					type: "RichText",
					key: "date",
					mandatory: false,
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Site",
					type: "RichText",
					key: "site",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Time",
					type: "RichText",
					key: "time",
					mandatory: false,
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Credits",
					type: "RichText",
					key: "credits",
					mandatory: false,
					hideable: true,
					humanReadable: true,
				},
				{
					title: "ButtonToTop",
					type: "ComponentContainer",
					whiteList: ["ButtonToTop"],
					key: "button",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							theme: "garrigues-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/defaultAlt.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/accentAlt.png`,
								},
							],
						},
						{
							theme: "ipam-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accentAlt.png`,
								},
							],
						},
						{
							theme: "iade-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accentAlt.png`,
								},
							],
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "RelatedProgramsElement",
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
		},
		theme: "defaultAlt",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		title: "Lorem ipsum dolor sit amet",
		date: "Lorem ipsum dolor",
		site: "Lorem ipsum dolor",
		time: "Lorem ipsum dolor",
		credits: "Lorem ipsum dolor",
		button: {
			component: "ButtonToTop",
			buttonText: "Solicita tu admisión",
			appearance: "primary",
			size: "medium",
		},
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/RelatedPrograms_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/RelatedPrograms_x2.png`,
	},
});
