import thumbnail from "@axthumbs/modules/FormTypeAreaLanding.png";
import BirthdayField from "@schemas/commons/BirthdayField";
import mountSchemaAndQuickFactsFields from "@schemas/commons/FieldsQuickFacts";
import FormFields from "@schemas/commons/FormFields";
import InternationalExtendedInformation from "@schemas/commons/InternationalExtendedInformation";
import PrivacyPoliceFormType from "@schemas/commons/PrivacityPoliceFormType";
import ShowDefaultMethodOfAdmission from "@schemas/commons/ShowDefaultMethodOfAdmission";
import ShowSelectorBachelorDegree from "@schemas/commons/ShowSelectorBachelorDegree";
import StudyLandingDistributor from "@schemas/commons/StudyLandingDistributor";
import TitleAndSubtitle from "@schemas/commons/TitleAndSubtitle";
import { createSchema } from "@utils/";

const schemaBase = {
	schemaType: "module",
	displayName: "Area Landing",
	component: "FormTypeAreaLanding",
	category: "FormType",
	dataPacks: null,
	configTabs: [
		{
			title: "FormTypeAreaLanding",
			fields: [
				...TitleAndSubtitle.content,
				{
					...StudyLandingDistributor.content,
				},
				{
					...BirthdayField.content,
				},
				{
					...InternationalExtendedInformation.content,
				},
				{
					...ShowSelectorBachelorDegree.content,
				},
				{
					...ShowDefaultMethodOfAdmission.content,
				},
				{
					title: "Quick Facts",
					type: "ComponentArray",
					whiteList: ["QuickFactsLandingsPortugal"],
					key: "quickFacts",
					maxItems: 1,
					mandatory: true,
				},
				...PrivacyPoliceFormType.content,
				...FormFields.content,
			],
		},
	],
	default: {
		title: "AreaLanding",
		component: "FormTypeAreaLanding",
		...TitleAndSubtitle.defaults,
		formType: "AreaLanding",
		data: {
			...StudyLandingDistributor.default,
		},
		hasDistributorData: true,
		quickFacts: [
			{
				component: "QuickFactsLandingsPortugal",
				data: {
					mode: "auto",
					sources: [{ structuredData: "QUICK_FACTS" }],
					order: "alpha",
				},
				hasDistributorData: true,
			},
		],
		...BirthdayField.default,
		...InternationalExtendedInformation.default,
		...ShowSelectorBachelorDegree.default,
		...ShowDefaultMethodOfAdmission.default,
		...PrivacyPoliceFormType.default,
		...FormFields.defaults,
	},
	thumbnails: {
		"1x": thumbnail,
		"2x": thumbnail,
	},
};

const schema = mountSchemaAndQuickFactsFields({
	schemaBase,
	hideable: ["courseMode"],
});

export default createSchema({ ...schema });
