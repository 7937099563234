export default {
	BLOG: {
		title: "Blog",
		dataPacks: ["BLOG"],
		local: false,
		taxonomy: false,
		fromPage: true,
		translate: true,
		schema: {
			templates: ["BlogDetail"],
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					from: "image",
				},
				{
					title: "Main Title",
					type: "TextField",
					humanReadable: true,
					key: "mainTitle",
					from: "mainTitle",
				},
				{
					title: "Publication Date",
					type: "DateField",
					selectsRange: true,
					key: "publicationDate",
					from: "publicationDate",
					indexable: true,
				},
				{
					title: "Update Date",
					type: "DateField",
					selectsRange: true,
					key: "updateDate",
					from: "updateDate",
					indexable: true,
					hideable: true,
				},
				{
					title: "Subtitle",
					type: "TextField",
					humanReadable: true,
					key: "subtitle",
					from: "subtitle",
				},
				{
					title: "Content",
					type: "Wysiwyg",
					humanReadable: true,
					key: "content",
					from: "content",
				},

				{
					title: "Category",
					type: "AsyncCheckGroup",
					source: "BLOG_CATEGORY",
					key: "blogCategory",
					from: "blogCategory",
					mandatory: true,
				},
				{
					title: "Tag",
					type: "AsyncCheckGroup",
					source: "BLOG_TAG",
					key: "blogTag",
					from: "blogTag",
					mandatory: true,
				},
				{
					title: "Author",
					type: "AsyncSelect",
					source: "BLOG_AUTHOR",
					key: "blogAuthor",
					from: "blogAuthor",
					mandatory: true,
				},
			],
			searchFrom: ["mainTitle", "subtitle", "content"],
		},
		clone: null,
		defaultValues: null,
	},
};
