export const SOCIAL_SHARE_LINKS = {
	facebook: "https://www.facebook.com/sharer.php?u=",
	twitter: "https://twitter.com/intent/tweet?source=webclient&text=",
	linkedin: "https://www.linkedin.com/shareArticle?mini=true&url=",
	whatsapp: "whatsapp://send?text=",
};

export const SOCIAL_ICONS_MAP = {
	flicker: "flickr",
	instagram: "instagram",
	linkedIn: "linkedin",
	facebook: "facebook",
	twitter: "twitter",
	youtube: "youtube",
	newsletter: "email",
	tiktok: "tiktok",
	snapchat: "snapchat",
};

export const SOCIAL_IDS = {
	facebook: "social-button-facebook",
	instagram: "social-button-instagram",
	twitter: "social-button-twitter",
	linkedin: "social-button-linkedin",
	youtube: "social-button-youtube",
};
