import { instance } from "@instance-config";
import { createSchema } from "@utils/index";

const isIPAM = instance.includes("IPAM");
const isIADE = instance.includes("IADE");

export default createSchema({
	schemaType: "component",
	displayName: "Phone Numbers List",
	component: "PhoneNumbersList",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},
				...(isIPAM || isIADE
					? [
							{
								title: "Title into Lightbox",
								type: "TextField",
								key: "titleLightbox",
								humanReadable: true,
							},
							{
								title: "Subtitle into Lightbox",
								type: "TextField",
								key: "subtitleLightbox",
								humanReadable: true,
							},
					  ]
					: []),
				{
					title: "Phone Number Element",
					type: "ComponentArray",
					whiteList: ["PhoneNumberElement"],
					key: "elements",
				},
			],
		},
	],
	default: {
		component: "PhoneNumbersList",
		title: "Contacta-nos",
		...(isIPAM && {
			titleLightbox: "Tem dúvidas?",
			subtitleLightbox:
				"Temos à sua disposição uma equipa de advisors que está disponível para esclarecer quaisquer dúvidas sobre os programas e como ingressar no IPAM.",
		}),
		...(isIADE && {
			titleLightbox: "Tens dúvidas?",
			subtitleLightbox:
				"A nossa equipa de Admissões está disponível para responder a qualquer dúvida que possas ter sobre os cursos ou como ingressar no IADE.",
		}),
		elements: [
			{
				component: "PhoneNumberElement",
				title: "Presencial:",
				phone: "000 000 000",
			},
			{
				component: "PhoneNumberElement",
				title: "Online:",
				phone: "000 000 000",
			},
		],
	},
	thumbnails: {},
});
