import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Landing Events Card",
	component: "LandingEventCard",
	dataPacks: null,
	category: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
					advanced: true,
					hideable: true,
				},
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					hideable: true,
				},
				{
					title: "Event Date",
					type: "DateField",
					key: "eventDate",
					hideable: true,
					selectsRange: true,
				},
				{
					title: "Event Hour",
					type: "TextField",
					key: "eventHour",
					placeholder: "hh:mm",
					helptext: "hh:mm",
					hideable: true,
				},
				{
					title: "Subtitle",
					type: "RichText",
					key: "subtitle",
					humanReadable: true,
				},
				{
					title: "Description",
					type: "RichText",
					key: "description",
					humanReadable: true,
				},
				{
					title: "Campaign ID",
					type: "TextField",
					key: "idCampaign",
					mandatory: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
	],
	default: {
		component: "LandingEventCard",
		title: { content: "Title", tag: "h3" },
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			url: "",
		},
		eventDate: "2021/01/01",
		eventHour: "00:00",
		subtitle:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
		description:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
		idCampaign: "",
		button: {
			component: "Button",
			buttonText: "Leer más",
			url: {
				href: "",
			},
			appearance: "primary",
			size: "big",
		},
	},
	thumbnails: {},
});
