const StudyDistributorFormType = {
	content: [
		{
			title: "Study Landing Distributor",
			type: "ComponentArray",
			whiteList: ["FormTypeStudyDistributor"],
			key: "studyDistributor",
			hiddeable: true,
		},
	],
	default: {
		studyDistributor: [
			{
				component: "FormTypeStudyDistributor",
				data: {
					mode: "auto",
					sources: [
						{ structuredData: "STUDY" },
						{ structuredData: "EXTERNAL_STUDY" },
					],
					order: "alpha",
					allLanguages: true,
					preferenceLanguage: true,
					quantity: 0,
				},
				hasDistributorData: true,
			},
		],
	},
};
export default StudyDistributorFormType;
