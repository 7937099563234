const menuItems = {
	// Schema without fields
	//   link: { fields: [] },
	//   group: { fields: [] },

	// Schema with fields
	link: {
		fields: [
			{
				type: "TextField",
				humanReadable: true,
				title: "Auxiliar text",
				key: "auxText",
			},
			{
				type: "ImageField",
				title: "Image",
				key: "image",
			},
			{
				type: "ToggleField",
				title: "Check it if the link has a special behavior.",
				key: "special",
			},
		],
	},

	group: {
		fields: [
			{
				type: "TextField",
				humanReadable: true,
				title: "Auxiliar text",
				key: "auxText",
			},
			{
				type: "ImageField",
				title: "Image",
				key: "image",
			},
			{
				type: "ToggleField",
				title: "Check it if the link has a special behavior.",
				key: "special",
			},
		],
	},
};

export default menuItems;
