import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Table Row",
	component: "TableRow",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
					hideable: false,
					humanReadable: true,
				},
				{
					title: "Credits",
					type: "TextField",
					key: "credits",
					mandatory: false,
					hideable: false,
				},
				{
					title: "File Name",
					type: "TextField",
					key: "fileName",
					mandatory: false,
					hideable: false,
				},
				{
					title: "File",
					type: "FileField",
					key: "file",
				},
			],
		},
	],
	default: {
		component: "TableRow",
		title: "Lorem ipsum",
		credits: "0",
		fileName: "pdf",
		file: "",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/TableRow@2x`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/TableRow@2x`,
	},
});
