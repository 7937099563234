import { createSchema } from "@utils/index";

export const artDirectionImageHorizontalVerticalDefaults = {
	component: "ArtDirectionImageHorizontalVertical",
	alt: "",
	title: "Background image",
};

export default createSchema({
	schemaType: "component",
	displayName: "Art Direction Image Horizontal Vertical",
	component: "ArtDirectionImageHorizontalVertical",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Alternative Text",
					type: "TextField",
					key: "alt",
					mandatory: true,
					hideable: false,
					humanReadable: true,
					helptext:
						"This text replaces the alt text of each image, so it is not necessary to fill in the alt for the images independently.",
				},
				{
					title: "Images",
					type: "FieldGroup",
					key: "images",
					fields: [
						{
							title: "Horizontal image",
							type: "ImageField",
							key: "imageHorizontal",
							mandatory: true,
							hideable: false,
						},
						{
							title: "Vertical image",
							type: "ImageField",
							key: "imageVertical",
							mandatory: true,
							hideable: false,
						},
					],
				},
			],
		},
	],
	default: artDirectionImageHorizontalVerticalDefaults,
	thumbnails: {},
});
