export default {
	LANDINGS: {
		title: "Landings",
		dataPacks: ["LANDINGS"],
		local: false,
		taxonomy: false,
		fromPage: false,
		translate: false,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
					from: "title",
					mandatory: true,
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
