import thumbnail from "@axthumbs/modules/FormTypeWebinar.png";
import StudyDistributorFormType from "@schemas/commons/StudyDistributorFormType";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Webinar",
	component: "FormTypeWebinar",
	category: "FormType",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					humanReadable: true,
				},
				{
					title: "Subtitle",
					type: "Wysiwyg",
					key: "subtitle",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Button text",
					type: "TextField",
					key: "buttonText",
					humanReadable: true,
				},
				{
					title: "Confirmation message title",
					type: "TextField",
					key: "confirmationMessageTitle",
					humanReadable: true,
				},
				{
					title: "Confirmation message detail",
					type: "TextField",
					key: "confirmationMessageDetail",
					humanReadable: true,
				},
				{
					title: "Error message title",
					type: "TextField",
					key: "errorMessageTitle",
					humanReadable: true,
				},
				{
					title: "Error message detail",
					type: "TextField",
					key: "errorMessageDetail",
					humanReadable: true,
				},
				{
					title: "Question select label",
					type: "TextField",
					key: "questionSelectLabel",
					humanReadable: true,
				},
				{
					title: "Campaign ID",
					type: "TextField",
					key: "idCampaign",
				},
				...StudyDistributorFormType.content,
				{
					title: "Privacy policy",
					type: "ReferenceField",
					sources: [{ structuredData: "PRIVACY_POLICY" }],
					key: "data",
				},
			],
		},
	],
	default: {
		component: "FormTypeWebinar",
		formType: "Webinar",
		title: "Title",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		buttonText: "Enviar",
		confirmationMessageTitle: "Lorem ipsum.",
		confirmationMessageDetail:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		errorMessageTitle: "Lorem ipsum.",
		errorMessageDetail:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		questionSelectLabel: "É estudante Universidade Europeia / IADE / IPAM?*",
		idCampaign: "",
		...StudyDistributorFormType.default,
		data: {
			mode: "auto",
			sources: [{ structuredData: "PRIVACY_POLICY" }],
			order: "alpha",
			quantity: 1,
		},
		hasDistributorData: true,
	},
	thumbnails: {
		"1x": thumbnail,
		"2x": thumbnail,
	},
});
