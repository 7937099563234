import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Date Button",
	component: "DateButton",
	dataPacks: null,
	category: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Current page",
					type: "RadioGroup",
					key: "checked",
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
				},
				{
					title: "URL",
					type: "UrlField",
					key: "url",
					advanced: true,
					mandatory: true,
				},
			],
		},
	],
	default: {
		component: "DateButton",
		title: "1 julio 2022",
		checked: false,
		url: {
			href: "",
			linkTo: null,
			newTab: false,
			noFollow: false,
		},
	},
	thumbnails: {},
});
