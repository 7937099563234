export default {
	COMPANIES: {
		title: "Companies",
		dataPacks: ["COMPANIES"],
		local: false,
		taxonomy: false,
		fromPage: false,
		translate: true,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
					from: "title",
					mandatory: true,
				},
				{
					title: "Logo",
					type: "ImageField",
					key: "file",
					from: "file",
				},
				{
					title: "URL",
					type: "UrlField",
					advanced: true,
					key: "url",
				},
			],
		},
	},
};
