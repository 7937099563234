import thumbnail from "@axthumbs/modules/FormTypeDualProductLanding.png";
import BirthdayField from "@schemas/commons/BirthdayField";
import FormFields from "@schemas/commons/FormFields";
import InternationalExtendedInformation from "@schemas/commons/InternationalExtendedInformation";
import PrivacyPoliceFormType from "@schemas/commons/PrivacityPoliceFormType";
import ShowDefaultMethodOfAdmission from "@schemas/commons/ShowDefaultMethodOfAdmission";
import ShowSelectorBachelorDegree from "@schemas/commons/ShowSelectorBachelorDegree";
import StudyLandingDistributor from "@schemas/commons/StudyLandingDistributor";
import TitleAndSubtitle from "@schemas/commons/TitleAndSubtitle";
import { createSchema } from "@utils/";

export default createSchema({
	schemaType: "module",
	displayName: "Dual Product Landing",
	component: "FormTypeDualProductLanding",
	category: "FormType",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				...TitleAndSubtitle.content,
				{
					title: "Course properties",
					type: "RadioGroup",
					key: "courseProperties",
					options: [
						{
							title: "Course Mode",
							name: "courseMode",
							value: "courseMode",
						},
						{
							title: "Location",
							name: "location",
							value: "location",
						},
					],
				},
				{
					...BirthdayField.content,
				},
				{
					...InternationalExtendedInformation.content,
				},
				{
					...StudyLandingDistributor.content,
				},
				{
					...ShowSelectorBachelorDegree.content,
				},
				{
					...ShowDefaultMethodOfAdmission.content,
				},
				{
					title: "Quick Facts",
					type: "ComponentArray",
					whiteList: ["QuickFactsLandingsPortugal"],
					key: "quickFacts",
					mandatory: true,
				},
				...PrivacyPoliceFormType.content,
				...FormFields.content,
			],
		},
	],
	default: {
		title: "DualProductLanding",
		component: "FormTypeDualProductLanding",
		...TitleAndSubtitle.defaults,
		formType: "DualProductLanding",
		courseProperties: "courseMode",
		...BirthdayField.default,
		...InternationalExtendedInformation.default,
		...ShowSelectorBachelorDegree.default,
		...ShowDefaultMethodOfAdmission.default,
		data: {
			...StudyLandingDistributor.default,
			mode: "manual",
		},
		hasDistributorData: true,
		quickFacts: [
			{
				component: "QuickFactsLandingsPortugal",
				data: {
					mode: "auto",
					sources: [{ structuredData: "QUICK_FACTS" }],
					order: "alpha",
				},
				hasDistributorData: true,
			},
		],
		...PrivacyPoliceFormType.default,
		...FormFields.defaults,
	},
	thumbnails: {
		"1x": thumbnail,
		"2x": thumbnail,
	},
});
