import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Header Two Lines",
	component: "HeaderTwoLines",
	type: "header",
	category: "header",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Name",
					key: "title",
					type: "TextField",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Phone Numbers List",
					type: "ComponentContainer",
					whiteList: ["PhoneNumbersList"],
					key: "phoneNumbersList",
				},
				{
					title: "Whatsapp Numbers List",
					type: "ComponentContainer",
					whiteList: ["PhoneNumbersList"],
					key: "whatsappNumbersList",
				},
				{
					title: "Student Area",
					type: "TextField",
					key: "studentAreaLabel",
					hideable: true,
					humanReadable: true,
				},

				{
					title: "Intranet label",
					type: "TextField",
					key: "intranetLinkText",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Intranet link",
					type: "UrlField",
					key: "intranet",
					advanced: true,
					hideable: true,
				},

				{
					title: "Student portal label",
					type: "TextField",
					key: "studentPortalLinkText",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Student portal link",
					type: "UrlField",
					key: "studentPortal",
					advanced: true,
					hideable: true,
				},
				{
					title: "Student Area (Do not use)",
					type: "UrlField",
					key: "studentArea",
					advanced: true,
					hideable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "setAsDefault",
					options: [{ title: "Set as Header default" }],
				},
				{
					title: "Main menu",
					type: "AsyncSelect",
					entity: "menu_containers",
					key: "mainMenu",
					mandatory: true,
				},
				{
					title: "Top menu",
					type: "AsyncSelect",
					entity: "menu_containers",
					key: "topMenu",
				},
			],
		},
	],

	default: {
		component: "HeaderTwoLines",
		type: "header",
		title: "Header Two Lines",
		phoneNumbersList: {
			component: "PhoneNumbersList",
			title: "Phone Numbers List",
			elements: [
				{
					component: "PhoneNumberElement",
					title: "Presencial:",
					phone: "000 000 000",
				},
				{
					component: "PhoneNumberElement",
					title: "Online:",
					phone: "000 000 000",
				},
			],
		},
		whatsappNumbersList: {
			component: "PhoneNumbersList",
			title: "Whatsapp",
			elements: [
				{
					component: "PhoneNumberElement",
					title: "Presencial:",
					phone: "000 000 000",
				},
				{
					component: "PhoneNumberElement",
					title: "Online:",
					phone: "000 000 000",
				},
			],
		},
		studentAreaLabel: "Login",
		intranetLinkText: "Intranet",
		studentPortalLinkText: "Portal do estudiante",
		studentArea: null, //
		note01: {
			title: "",
			text: "To configure social links go to settings/general/social. To activate search feature go to settings/actionables",
		},
		button: {
			component: "Button",
			buttonText: "Infórmate",
			appearance: "primary",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		setAsDefault: true,
		mainMenu: null,
		topMenu: null,
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HeaderTwoLines.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HeaderTwoLines.png`,
	},
});
