import { instance } from "@instance-config";

const isIPAM = instance.includes("IPAM");
const isIADE = instance.includes("IADE");
const isIADEES = instance.includes("iadeES");
const isCEG = instance.includes("CEG");

const STUDY_TYPE = {
	value: "STUDY_TYPE",
	name: "STUDY_TYPE",
	title: "Program type",
};
const COURSE_MODE = {
	value: "COURSE_MODE",
	name: "COURSE_MODE",
	title: "Course Mode",
};
const LOCATION = { value: "LOCATION", name: "LOCATION", title: "Location" };
const LANGUAGE = { value: "LANGUAGE", name: "LANGUAGE", title: "Language" };
const KNOWLEDGE_AREA = {
	value: "KNOWLEDGE_AREA",
	name: "KNOWLEDGE_AREA",
	title: "Knowledge Area",
};

const defaultOptionsFiltersCEG = [{ ...COURSE_MODE }, { ...STUDY_TYPE }];
const defaultOptionsFiltersPortugal = [{ ...COURSE_MODE }, { ...STUDY_TYPE }];

const optionsFiltersIADE = [
	{ ...COURSE_MODE },
	{ ...STUDY_TYPE },
	{ ...LOCATION },
	{ ...LANGUAGE },
	{ ...KNOWLEDGE_AREA },
];

const optionsFiltersIPAM = [
	{ ...COURSE_MODE },
	{ ...STUDY_TYPE },
	{ ...LOCATION },
	{ ...LANGUAGE },
	{ ...KNOWLEDGE_AREA },
];

export const optionsFilter = () => {
	if (isIPAM) {
		return optionsFiltersIPAM;
	}
	if (isIADE || isIADEES) {
		return optionsFiltersIADE;
	}
	if (isCEG) {
		return defaultOptionsFiltersCEG;
	}
	return defaultOptionsFiltersPortugal;
};
