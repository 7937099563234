import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Career Opportunities Card",
	component: "CareerOpportunitiesCard",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					mandatory: true,
				},
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Description",
					type: "TextField",
					key: "description",
					hideable: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "CareerOpportunitiesCard",
		image: {
			alt: "",
			publicId: "IADEdev/pen_11a69674-c184-4121-9f34-96552fe81417",
		},
		title: "Lorem ipsum",
		description:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
	},

	thumbnails: {},
});
