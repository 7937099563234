export default {
	schemaType: "template",
	displayName: "Admission Request Files",
	component: "AdmissionRequestFiles",
	dataPacks: ["STUDY"],
	type: { label: "Study", value: "study" },
	content: [
		{
			title: "Title",
			type: "TextField",
			humanReadable: true,
			key: "title",
		},
	],

	default: {
		type: "template",
		templateType: "AdmissionRequestFiles",
		title:
			"En esta página podrás adjuntar los documentos necesarios para formalizar la admisión",
	},

	thumbnails: {},
};
