import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Staff Bio",
	component: "StaffBio",
	category: "content",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					hideable: true,
				},
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Course",
					type: "TextField",
					key: "course",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Information",
					type: "RichText",
					key: "info",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Email",
					type: "TextField",
					key: "email",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Document Link",
					type: "UrlField",
					key: "documentLink",
					helptext: "Add link to a pdf or any document type",
					hideable: true,
				},
				{
					title: "Bio Title",
					type: "TextField",
					key: "bioTitle",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Bio",
					type: "RichText",
					key: "bio",
					mandatory: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "StaffBio",
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			url: "",
		},
		title: "Lorem Ipsums",
		course: "Lorem ipsum ",
		info: "Lorem ipusm",
		email: "example@email.com",
		documentLink: {
			text: "Descargar PDF",
			href: "",
			external: true,
			newTab: false,
			noFollow: false,
			linkTo: null,
		},
		bioTitle: "Lorem Ipsum",
		bio: "Cu nam labores lobortis definiebas, ei aliquyam salutatus persequeris quo, cum eu nemore fierent dissentiunt. Per vero dolor id, vide democritum scribentur eu vim, pri erroribus temporibus ex. Euismod molestie offendit has no. Quo te semper invidunt quaestio, per vituperatoribus sadipscing ei, partem aliquyam sensibus in cum.",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StaffBio_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StaffBio_x2.png`,
	},
});
