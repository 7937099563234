import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Description Card",
	component: "DescriptionCard",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					mandatory: true,
					advanced: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Description",
					type: "RichText",
					key: "description",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "DescriptionCard",
		title: {
			content: "Description Card Title",
			tag: "h4",
		},
		description: "Lorem ipsum dolor sit amet",
		button: {
			component: "Button",
			buttonText: "Ver detalle",
			appearance: "link",
			size: "small",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
	},

	thumbnails: {},
});
