import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Testimonials Card Landing",
	component: "TestimonialsCardLanding",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "manualTitle",
					hideable: true,
				},
				{
					title: "Name",
					type: "TextField",
					key: "name",
					hideable: true,
				},
				{
					title: "Quote",
					type: "TextField",
					key: "quote",
					mandatory: true,
				},
				{
					title: "Position",
					type: "RichText",
					key: "position",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Promotion",
					type: "RichText",
					key: "promotion",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "TestimonialsCardLanding",
		manualTitle: "Lorem ipsum dolor sit amet",
		name: "Lorem Ipsum",
		quote: '"Lorem ipsum dolor sit amet"',
		position: "Lorem Ipsum dolor",
		promotion:
			"Lorem ipsum dolor sit amet | consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
		},
	},

	thumbnails: {},
});
