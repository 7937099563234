export default {
	WORD: {
		title: "Word",
		dataPacks: ["WORD"],
		local: false,
		taxonomy: false,
		fromPage: true,
		translate: true,
		schema: {
			templates: ["Word"],
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					from: "image",
				},
				{
					title: "Main Title",
					type: "TextField",
					key: "mainTitle",
					from: "mainTitle",
				},
				{
					title: "Content",
					type: "Wysiwyg",
					key: "content",
					from: "content",
				},
				{
					title: "Category",
					type: "AsyncCheckGroup",
					source: "WORD_CATEGORY",
					key: "wordCategory",
					from: "wordCategory",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
