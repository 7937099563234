export default {
	schemaType: "component",
	displayName: "File",
	component: "File",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "File",
					type: "FileField",
					key: "file",
				},
				{
					title: "Document Title",
					type: "TextField",
					humanReadable: true,
					key: "documentTitle",
				},
				{
					title: "Alternative Text",
					type: "TextField",
					humanReadable: true,
					key: "alternativeText",
				},
			],
		},
	],
	default: {
		component: "File",
		file: "",
		documentTitle: "",
		alternativeText: "",
	},
};
