import thumbnail1x from "@axthumbs/thumbnails/herolanding.png";
import thumbnail2x from "@axthumbs/thumbnails/herolanding_x2.png";
import { artDirectionImageHorizontalVerticalDefaults } from "@schemas/components/ArtDirectionImageHorizontalVertical";
import { createSchema } from "@utils";

export default createSchema({
	schemaType: "module",
	displayName: "Hero Landing",
	component: "HeroLanding",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					mandatory: true,
					advanced: true,
					default: { tag: "h1", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Subtitle",
					type: "HeadingField",
					humanReadable: true,
					key: "subtitle",
					mandatory: false,
					hideable: true,
					advanced: true,
					default: { tag: "h2", content: "" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Background image",
					type: "ComponentContainer",
					whiteList: ["ArtDirectionImageHorizontalVertical"],
					key: "backgroundImage",
					hideable: false,
				},
			],
		},
	],

	default: {
		component: "HeroLanding",
		theme: "default",
		title: { content: "Title", tag: "h1" },
		backgroundImage: artDirectionImageHorizontalVerticalDefaults,
	},
	thumbnails: {
		"1x": thumbnail1x,
		"2x": thumbnail2x,
	},
});
