import thumbnail from "@axthumbs/modules/FormTypeEvent.png";
import { createSchema } from "@utils/";

export default createSchema({
	schemaType: "module",
	displayName: "Multievent",
	component: "FormTypeMultievent",
	category: "FormType",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
				},
				{
					title: "Subtitle",
					type: "Wysiwyg",
					humanReadable: true,
					key: "subtitle",
					hideable: true,
				},
				{
					title: "Button text",
					type: "TextField",
					humanReadable: true,
					key: "buttonText",
				},
				{
					title: "Confirmation message title",
					type: "TextField",
					humanReadable: true,
					key: "confirmationMessageTitle",
				},
				{
					title: "Confirmation message detail",
					type: "TextField",
					humanReadable: true,
					key: "confirmationMessageDetail",
				},
				{
					title: "Error message title",
					type: "TextField",
					humanReadable: true,
					key: "errorMessageTitle",
				},
				{
					title: "Error message detail",
					type: "TextField",
					humanReadable: true,
					key: "errorMessageDetail",
				},
				{
					title: "Workshops Select Label",
					type: "TextField",
					humanReadable: true,
					key: "workshopsSelectLabel",
				},
				{
					title: "Privacy policy",
					type: "ReferenceField",
					sources: [{ structuredData: "PRIVACY_POLICY" }],
					key: "data",
				},
			],
		},
	],
	default: {
		component: "FormTypeMultievent",
		formType: "Multievent",
		title: "Title",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		buttonText: "Enviar",
		confirmationMessageTitle: "Lorem ipsum.",
		confirmationMessageDetail:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		errorMessageTitle: "Lorem ipsum.",
		errorMessageDetail:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		workshopsSelectLabel: "Workshops",
		data: {
			mode: "auto",
			sources: [{ structuredData: "PRIVACY_POLICY" }],
			order: "alpha",
			quantity: 1,
		},
		hasDistributorData: true,
	},
	thumbnails: {
		"1x": thumbnail,
		"2x": thumbnail,
	},
});
