/**
 *
 * @type {{pageType: undefined, pageLanguage: undefined, url: undefined}}
 *
 *  * @param {String} pageType - landingpage
 *  * @param {String} pageLanguage - Idioma de la página
 *  * @param {String} url -  URL sin ningún tipo de parámetro añadido (utms, búsquedas en el sitio web, aplicación de filtros en la página de listado)
 *
 */
const BASE_LANDINGS_EVENTS = {
	pageType: "landingpage",
	pageLanguage: undefined,
	url: undefined,
};

/**
 *
 * @param formType - Template type
 * @param currentLang - Current language
 * @param fullUrl - Full url
 *
 * @returns Object
 */
export const pushDataLayerEventLanding = ({ currentLang, fullUrl }) => {
	const _datalayer = Object.assign({ ...BASE_LANDINGS_EVENTS });
	_datalayer.pageLanguage = currentLang;
	_datalayer.url = fullUrl;

	return _datalayer;
};
