import en_GB from "@translations/en_GB";
import { deepClone } from "@translations/utils";

const iadeESEN = deepClone(en_GB);

iadeESEN.eventForm.sendBtn = "PENDING TO TRANSLATION";
iadeESEN.form.button = "PENDING TO TRANSLATION";
iadeESEN.form.fields.button = "PENDING TO TRANSLATION";
iadeESEN.form.fields.label.send = "PENDING TO TRANSLATION";
iadeESEN.form.fields.label.courseProperties.courseMode = "In the modality";
iadeESEN.form.header.subtitle = "PENDING TO TRANSLATION";
iadeESEN.form.header.text = "PENDING TO TRANSLATION";
iadeESEN.form.legalText = "PENDING TO TRANSLATION";
iadeESEN.form.legalText2 = "PENDING TO TRANSLATION";
iadeESEN.formAward.enviar = "PENDING TO TRANSLATION";
iadeESEN.formInfo.requestInfoText = "PENDING TO TRANSLATION";
iadeESEN.formInfo.sendBtn = "PENDING TO TRANSLATION";

export default iadeESEN;
