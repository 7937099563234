export default {
	JOB_OFFERS: {
		title: "Job Offers",
		dataPacks: ["JOB_OFFERS"],
		local: false,
		taxonomy: false,
		fromPage: true,
		translate: true,
		expirationDateField: "expirationDate",
		schema: {
			templates: ["JobOfferDetail"],
			fields: [
				{
					title: "Job Position",
					type: "HeadingField",
					humanReadable: true,
					key: "jobPosition",
					from: "jobPosition",
					default: { tag: "h1", content: "Job Offer Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
					advanced: true,
					mandatory: true,
				},
				{
					title: "Job Offer Date",
					type: "DateField",
					key: "jobOfferDate",
					from: "jobOfferDate",
					mandatory: true,
					indexable: true,
				},
				{
					title: "Expiration Date",
					type: "DateField",
					key: "expirationDate",
					from: "expirationDate",
					// mandatory: true,
					// indexable: true,
					helptext:
						"This job offer will be automatically unpublished between 0:00 and 2:00 on the selected day.",
				},
				{
					title: "Company Name",
					type: "TextField",
					humanReadable: true,
					key: "company",
					from: "company",
				},
				{
					title: "Company logo",
					type: "ImageField",
					key: "companyLogo",
					from: "companyLogo",
					hideable: true,
					helptext: "Recommended size: 432x188px",
				},
				{
					title: "Department",
					type: "TextField",
					humanReadable: true,
					key: "department",
					from: "department",
				},
				{
					title: "Location",
					type: "AsyncCheckGroup",
					source: "LOCATION",
					key: "location",
					from: "location",
					mandatory: true,
				},
				{
					title: "Language",
					type: "AsyncCheckGroup",
					source: "LANGUAGE",
					key: "language",
					from: "language",
					mandatory: true,
				},
				{
					title: "Related Studies List",
					type: "AsyncCheckGroup",
					sources: [{ structuredData: "STUDY" }],
					key: "studiesList",
					from: "studiesList",
				},
				{
					title: "Job Short Description",
					type: "RichText",
					humanReadable: true,
					key: "shortDescription",
					from: "shortDescription",
					mandatory: true,
					helptext: "Used only in distributors",
				},
			],
			searchFrom: [], //'jobOfferTitle'
		},
		clone: null,
		defaultValues: null,
	},
};
