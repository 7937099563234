import { useCallback, useEffect, useState } from "react";
import { getWindow } from "ssr-window";

const window = getWindow();

export const useScrollPosition = ({ element = window, listen = true }) => {
	const [scrollPosition, setScrollPosition] = useState(window.scrollY);
	const [scrollType, setScrollType] = useState();

	const handleScroll = useCallback(
		(e) => {
			const scroll = e.currentTarget.scrollY;
			setScrollPosition(scroll);
			setTimeout(
				() => setScrollType(scroll >= scrollPosition ? "down" : "up"),
				50
			);
		},
		[scrollPosition]
	);

	useEffect(() => {
		if (listen) {
			setScrollPosition(element.scrollY);
			element.addEventListener("scroll", handleScroll);

			return () => {
				element.removeEventListener("scroll", handleScroll);
			};
		}
	}, [scrollPosition]);

	return [scrollPosition, scrollType];
};
