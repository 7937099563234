import { basicModuleList } from "@schemas/modules";

export default {
	schemaType: "template",
	displayName: "Job Offer Detail",
	component: "JobOfferDetail",
	dataPacks: ["JOB_OFFERS"],
	type: { label: "Job offers", value: "JOB_OFFERS", mode: "detail" },
	content: [
		{
			title: "Hero section",
			type: "ComponentContainer",
			whiteList: ["InnerTitle"],
			key: "innerTitleComp",
		},
		{
			title: "Job Position",
			type: "HeadingField",
			humanReadable: true,
			key: "jobPosition",
			default: { tag: "h1", content: "Job Offer Title" },
			options: [
				{ value: "h1", label: "H1" },
				{ value: "h2", label: "H2" },
				{ value: "h3", label: "H3" },
				{ value: "h4", label: "H4" },
				{ value: "span", label: "span" },
			],
			advanced: true,
			mandatory: true,
		},
		{
			title: "Job Offer Date",
			type: "DateField",
			key: "jobOfferDate",
			mandatory: true,
		},
		{
			title: "Expiration Date",
			type: "DateField",
			key: "expirationDate",
			helptext:
				"This job offer will be automatically unpublished between 0:00 and 2:00 on the selected day.",
		},
		{
			title: "Department",
			type: "TextField",
			humanReadable: true,
			key: "department",
		},
		{
			title: "Company Name",
			type: "TextField",
			humanReadable: true,
			key: "company",
		},
		{
			title: "Company logo",
			type: "ImageField",
			key: "companyLogo",
			hideable: true,
			helptext: "Recommended size: 432x188px",
		},
		{
			title: "Location",
			type: "AsyncCheckGroup",
			source: "LOCATION",
			key: "location",
			mandatory: true,
		},
		{
			title: "Language",
			type: "AsyncCheckGroup",
			source: "LANGUAGE",
			key: "language",
			mandatory: true,
		},
		{
			title: "Related Studies List",
			type: "AsyncCheckGroup",
			source: "STUDY",
			key: "studiesList",
		},
		{
			title: "Job Short Description",
			type: "RichText",
			humanReadable: true,
			key: "shortDescription",
			mandatory: true,
			helptext: "Used only in distributors",
		},
		{
			title: "Job Description section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: ["JobOfferTextBlock"],
			key: "jobDescriptionSection",
		},
		{
			title: "Contact Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: ["JobOfferTextBlockWithIcon"],
			key: "contactSection",
		},
		{
			title: "Job QuickFacts section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: ["JobOfferTextBlock"],
			key: "quickFactsSection",
		},
		{
			title: "More info",
			type: "ConditionalField",
			key: "moreInfoLink",
			hideable: true,
			options: [
				{
					value: "link",
					name: "link",
					title: "Web address:",
				},
				{
					value: "email",
					name: "email",
					title: "E-mail address:",
				},
			],
			fields: [
				{
					title: "E-mail",
					type: "TextField",
					humanReadable: true,
					key: "moreInfoLinkEmail",
					condition: "email",
				},
				{
					title: "Url",
					type: "UrlField",
					key: "moreInfoLinkUrl",
					advanced: true,
					condition: "link",
				},
			],
		},
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...basicModuleList],
			key: "mainSection",
			mandatory: false,
		},
	],
	default: {
		type: "template",
		templateType: "JobOfferDetail",
		innerTitleComp: {
			component: "InnerTitle",
			title: {
				content: "Job Offer Title",
				tag: "h4",
			},
			subtitle: null,
			backgroundImage: {
				component: "Image",
				alt: "Alt",
				file: {
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
				},
				veil: 0,
			},
			icon: false,
			breadcrumbsOnly: true,
			theme: "default",
			size: "small",
		},
		jobPosition: { content: "Job Position", tag: "h2" },
		contactSection: {
			component: "Section",
			name: "Contact Section",
			modules: [],
		},
		quickFactsSection: {
			component: "Section",
			name: "QuickFacts Section",
			modules: [],
		},
		jobDescriptionSection: {
			component: "Section",
			name: "QuickFacts Section",
			modules: [],
		},
		moreInfoLink: "link",
		moreInfoLinkUrl: {
			href: "",
			linkTo: null,
			newTab: true,
			noFollow: false,
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{
					component: "JobOfferDistributor",
					title: { content: "Otras ofertas", tag: "h2" },
					data: {
						mode: "auto",
						sources: [{ structuredData: "JOB_OFFERS" }],
						order: "alpha",
						quantity: 3,
					},
					button: {
						component: "Button",
						buttonText: "Ver todas los ofertas",
						appearance: "link",
						size: "small",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
					layout: "L001",
					hasDistributorData: true,
				},
			],
		},
	},
	thumbnails: {}, // @todo Añadir thumbs
};
