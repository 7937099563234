import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "News Card",
	component: "NewsCard",
	dataPacks: ["NEWS"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					mandatory: true,
				},
				{
					title: "Main Title",
					type: "TextField",
					key: "mainTitle",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Publication Date",
					type: "DateField",
					key: "publicationDate",
					selectsRange: true,
					mandatory: true,
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Content",
					type: "RichText",
					key: "content",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "NewsCard",
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			url: "",
		},
		mainTitle: "Lorem ipsum",
		publicationDate: "2021/01/01",
		subtitle:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
		content:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
		button: {
			component: "Button",
			buttonText: "Leer más",
			url: {
				href: "",
			},
			appearance: "link",
			size: "small",
		},
	},

	thumbnails: {},
});
