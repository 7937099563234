import thumbnail from "@axthumbs/templates/LandingEasyPackPortugal.png";
import { artDirectionImageHorizontalVerticalDefaults } from "@schemas/components/ArtDirectionImageHorizontalVertical";
import { basicModuleList } from "@schemas/modules";

import TypologySelect from "../commons/TypologySelect";

export default {
	schemaType: "template",
	displayName: "Easy Pack",
	component: "LandingEasyPackPortugal",
	dataPacks: ["LANDINGS"],
	category: "Landings",
	type: {
		label: "Landings",
		value: "landings",
		mode: "detail",
	},
	content: [
		{
			title: "Hero Section",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["HeroLanding"],
			key: "heroSection",
			mandatory: true,
		},
		{
			title: "Landing Introduction Portugal",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["LandingIntroduction"],
			key: "landingIntroduction",
			mandatory: true,
		},
		{ ...TypologySelect.content },
		{
			title: "Study",
			type: "ReferenceField",
			sources: [
				{ structuredData: "STUDY" },
				{ structuredData: "EXTERNAL_STUDY" },
			],
			key: "data",
			mandatory: true,
		},
		{
			title: "Form type",
			type: "ComponentArray",
			whiteList: ["FormTypeEasyPack"],
			key: "formType",
			maxItems: 1,
			mandatory: true,
		},
		{
			title: "EventList",
			type: "ComponentArray",
			whiteList: ["LandingEventList"],
			key: "eventList",
			maxItems: 1,
			mandatory: true,
		},
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...basicModuleList],
			key: "mainSection",
		},
	],

	default: {
		type: "template",
		templateType: "LandingEasyPackPortugal",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [
				{
					component: "HeroLanding",
					theme: "default",
					title: { content: "Title", tag: "h1" },
					backgroundImage: artDirectionImageHorizontalVerticalDefaults,
				},
			],
			sectionPosition: 1,
		},
		data: {
			mode: "auto",
			sources: [
				{ structuredData: "STUDY" },
				{ structuredData: "EXTERNAL_STUDY" },
			],
			order: "alpha",
			allLanguages: true,
			preferenceLanguage: true,
			quantity: 0,
		},
		hasDistributorData: true,
		formType: {
			component: "Section",
			name: "formType",
			modules: [
				{
					component: "FormTypeEasyPack",
					formType: "easyPack",
					title: "Title",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
					buttonText: "Enviar",
					confirmationMessageTitle: "Lorem ipsum.",
					confirmationMessageDetail:
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
					errorMessageTitle: "Lorem ipsum.",
					errorMessageDetail:
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
					easyPackSelectLabel: 'info "Easy Packs"',
					data: {
						mode: "auto",
						sources: [{ structuredData: "PRIVACY_POLICY" }],
						order: "alpha",
						quantity: 1,
					},
					hasDistributorData: true,
				},
			],
		},
		landingIntroduction: {
			component: "Section",
			name: "LandingIntroduction",
			modules: [
				{
					component: "LandingIntroduction",
					title: { content: "Title", tag: "h2" },
					detail:
						"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
				},
			],
		},
		eventList: {
			component: "Section",
			name: "eventList",
			modules: [
				{
					component: "LandingEventList",
					title: { content: "Title", tag: "h4" },
					theme: "default",
					elements: [
						{
							component: "LandingEventCard",
							title: { content: "Title 1", tag: "h3" },
							image: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							eventDate: "2021/01/01",
							eventHour: "00:00",
							subtitle:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							idCampaign: "",
							button: {
								component: "Button",
								buttonText: "Leer más",
								url: {
									href: "",
								},
								appearance: "primary",
								size: "small",
							},
						},
						{
							component: "LandingEventCard",
							title: { content: "Title 2", tag: "h3" },
							image: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							eventDate: "2021/01/01",
							eventHour: "00:00",
							subtitle:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							idCampaign: "",
							button: {
								component: "Button",
								buttonText: "Leer más",
								url: {
									href: "",
								},
								appearance: "primary",
								size: "small",
							},
						},
						{
							component: "LandingEventCard",
							title: { content: "Title 3", tag: "h3" },
							image: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							eventDate: "2021/01/01",
							eventHour: "00:00",
							subtitle:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							idCampaign: "",
							button: {
								component: "Button",
								buttonText: "Leer más",
								url: {
									href: "",
								},
								appearance: "primary",
								size: "small",
							},
						},
					],
				},
			],
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
		typology: TypologySelect.typology,
	},
	thumbnails: {
		"1x": thumbnail,
		"2x": thumbnail,
	},
};
