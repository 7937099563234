export default {
	MODALS: {
		title: "Modals",
		dataPacks: ["MODALS", "LANDINGS"],
		local: true,
		taxonomy: false,
		fromPage: true,
		translate: true,
		schema: {
			templates: ["Modal"],
			fields: [
				{
					title: "Start Date",
					type: "DateField",
					key: "startDate",
					from: "startDate",
				},
				{
					title: "End Date",
					type: "DateField",
					key: "endDate",
					from: "endDate",
				},
				{
					title: "Heading",
					type: "TextField",
					key: "heading",
					from: "heading",
					indexable: true,
				},
				{
					title: "Event Date",
					type: "DateField",
					key: "eventDate",
					from: "eventDate",
					indexable: true,
				},
				{
					title: "Event Location",
					type: "TextField",
					key: "eventLocation",
					from: "eventLocation",
				},
				{
					title: "Event Hour",
					type: "TextField",
					key: "eventHour",
					from: "eventHour",
				},
				{
					title: "Visual content",
					type: "ComponentContainer",
					key: "visualContent",
					from: "visualContent",
				},
				{
					title: "Modal type",
					type: "Select",
					key: "modalType",
					from: "modalType",
				},
				{
					title: "Style",
					type: "VisualUniqueSelection",
					key: "theme",
					from: "theme",
				},
				{
					title: "Wysiwyg",
					type: "RichText",
					key: "wysiwyg",
					from: "wysiwyg",
				},
				{
					title: "CTA",
					type: "ComponentContainer",
					key: "cta",
					from: "cta",
				},
				{
					title: "File",
					type: "FileField",
					key: "file",
					from: "file",
				},
				{
					title: "File name",
					type: "TextField",
					key: "fileName",
					from: "fileName",
				},
				{
					title: "Target pages",
					type: "ReferenceField",
					sources: [
						{ structuredData: "BLOG" },
						{ structuredData: "EVENTS" },
						{ structuredData: "INFO_LANDINGS" },
						{ structuredData: "NEWS" },
						{ structuredData: "STAFF" },
						{ structuredData: "STUDY" },
					],
					key: "target",
					from: "target",
				},

				{
					title: "Modal trigger",
					type: "CheckGroup",
					key: "trigger",
					from: "trigger",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
