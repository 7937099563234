import thumbnail from "@axthumbs/modules/FormTypeDualAreaLanding.png";
import { instance } from "@instance-config";
import mountSchemaAndQuickFactsFields from "@schemas/commons/FieldsQuickFacts";
import FormFields from "@schemas/commons/FormFields";
import PrivacyPoliceFormType from "@schemas/commons/PrivacityPoliceFormType";
import ShowSelectorBachelorDegree from "@schemas/commons/ShowSelectorBachelorDegree";
import StudyLandingDistributor from "@schemas/commons/StudyLandingDistributor";
import TitleAndSubtitle from "@schemas/commons/TitleAndSubtitle";
import { createSchema } from "@utils/";

const isIADE = instance.includes("IADE");

const schemaBase = {
	schemaType: "module",
	displayName: "Dual Area Landing",
	component: "FormTypeDualAreaLanding",
	category: "FormType",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				...TitleAndSubtitle.content,
				{
					title: "Course properties",
					type: "RadioGroup",
					key: "courseProperties",
					options: [
						{
							title: "Course Mode",
							name: "courseMode",
							value: "courseMode",
						},
						{
							title: "Location",
							name: "location",
							value: "location",
						},
						{
							title: "Program Type",
							name: "programType",
							value: "programType",
						},
						...(isIADE
							? [
									{
										title: "Knowledge Area",
										name: "knowledgeArea",
										value: "knowledgeArea",
									},
							  ]
							: []),
					],
				},
				{
					...ShowSelectorBachelorDegree.content,
				},
				{
					...StudyLandingDistributor.content,
				},
				{
					title: "Quick Facts",
					type: "ComponentArray",
					whiteList: ["QuickFactsLandingsPortugal"],
					key: "quickFacts",
					maxItems: 1,
					mandatory: true,
				},
				...PrivacyPoliceFormType.content,
				...FormFields.content,
			],
		},
	],
	default: {
		title: "DualAreaLanding",
		component: "FormTypeDualAreaLanding",
		...TitleAndSubtitle.defaults,
		formType: "DualAreaLanding",
		courseProperties: "courseMode",
		...ShowSelectorBachelorDegree.default,
		data: {
			...StudyLandingDistributor.default,
			mode: "auto",
		},
		hasDistributorData: true,
		quickFacts: [
			{
				component: "QuickFactsLandingsPortugal",
				data: {
					mode: "auto",
					sources: [{ structuredData: "QUICK_FACTS" }],
					order: "alpha",
				},
				hasDistributorData: true,
			},
		],
		...PrivacyPoliceFormType.default,
		...FormFields.defaults,
	},
	thumbnails: {
		"1x": thumbnail,
		"2x": thumbnail,
	},
};

const schema = mountSchemaAndQuickFactsFields({
	schemaBase,
	hideable: ["courseMode"],
});

export default createSchema({ ...schema });
