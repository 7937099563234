import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Privacy policy",
	component: "FormTypePrivacyPolicy",
	dataPacks: ["LANDINGS"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
				},
				{
					title: "Privacy policy",
					type: "ReferenceField",
					sources: [{ structuredData: "PRIVACY_POLICY" }],
					key: "data",
				},
			],
		},
	],

	default: {
		component: "FormTypePrivacyPolicy",
		title: "Privacy policy",
		data: {
			mode: "auto",
			sources: [{ structuredData: "PRIVACY_POLICY" }],
			order: "alpha",
		},
		hasDistributorData: true,
	},
	thumbnails: {},
});
