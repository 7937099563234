export default {
	PRIVACY_POLICY: {
		title: "Form Privacy policy",
		dataPacks: ["LANDINGS"],
		local: true,
		taxonomy: false,
		fromPage: false,
		translate: true,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
					mandatory: true,
				},
				{
					title: "Description",
					type: "RichText",
					humanReadable: true,
					key: "description",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
