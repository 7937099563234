import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Header",
	component: "Header",
	type: "header",
	category: "header",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Name",
					key: "title",
					type: "TextField",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Phone Numbers List",
					type: "ComponentContainer",
					whiteList: ["PhoneNumbersList"],
					key: "phoneNumbersList",
					mandatory: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "setAsDefault",
					options: [{ title: "Set as Header default" }],
				},
				{
					title: "Main menu",
					type: "AsyncSelect",
					entity: "menu_containers",
					key: "mainMenu",
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "Header",
		type: "header",
		title: "Header name",
		note01: {
			title: "",
			text: "To configure social links go to settings/general/social. To activate search feature go to settings/actionables",
		},
		phoneNumbersList: {
			component: "PhoneNumbersList",
			title: "Llámanos",
			elements: [
				{
					component: "PhoneNumberElement",
					title: "Admisiones:",
					phone: "+34 91 514 53 30",
				},
				{
					component: "PhoneNumberElement",
					title: "Información General:",
					phone: "+34 91 666 66 66",
				},
			],
		},
		button: {
			component: "Button",
			buttonText: "Infórmate",
			appearance: "primary",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		setAsDefault: true,
		mainMenu: null,
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Header_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Header_x2.png`,
	},
});
