export default [
	{
		key: "TopMenu",
		display: "Top Menu",
	},
	{
		key: "LegalMenu",
		display: "Legal Menu",
	},
	{
		key: "MainMenu",
		display: "Main Menu",
	},
	{
		key: "FooterMenu",
		display: "Footer Menu",
	},
];
