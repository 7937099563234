import iconEmail from "@axthumbs/layout/joboffertextblockwithicon-email.png";
import iconInfo from "@axthumbs/layout/joboffertextblockwithicon-info.png";
import iconPin from "@axthumbs/layout/joboffertextblockwithicon-pin.png";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Text block",
	component: "JobOfferTextBlockWithIcon",
	category: "",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Description",
					type: "RichText",
					key: "description",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Icon",
					type: "VisualUniqueSelection",
					key: "iconName",
					options: [
						{
							img: iconInfo,
							value: "info",
						},
						{
							img: iconEmail,
							value: "email",
						},
						{
							img: iconPin,
							value: "pin",
						},
					],
					columns: 6,
					mandatory: true,
				},
			],
		},
	],
	default: {
		component: "JobOfferTextBlockWithIcon",
		title: "Lorem ipsum",
		description:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
		iconName: "info",
	},

	thumbnails: {},
});
