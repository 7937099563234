import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Sponsors Logos Lightbox",
	component: "SponsorsLogosLightbox",
	category: "collections",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Description",
					type: "RichText",
					key: "text",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Sponsors",
					type: "ReferenceField",
					sources: [{ structuredData: "COMPANIES" }],
					key: "data",
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "SponsorsLogosLightbox",
		title: "Lorem Ipsum",
		text: "Lorem Ipsum",
		data: {
			mode: "auto",
			sources: [{ structuredData: "COMPANIES" }],
			order: "alpha",
			quantity: 15,
		},
		button: {
			component: "Button",
			buttonText: "Ver página",
			appearance: "link",
			size: "small",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		hasDistributorData: true,
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/SponsorsLogosLightbox_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/SponsorsLogosLightbox_x2.png`,
	},
});
