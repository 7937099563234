const TitleAndSubtitle = {
	content: [
		{
			title: "Title",
			type: "HeadingField",
			humanReadable: true,
			key: "contentTitle",
			default: { tag: "h2", content: "Title" },
			options: [
				{ value: "h1", label: "H1" },
				{ value: "h2", label: "H2" },
				{ value: "h3", label: "H3" },
				{ value: "h4", label: "H4" },
				{ value: "span", label: "span" },
			],
			advanced: true,
			hideable: true,
		},
		{
			title: "Subtitle",
			type: "Wysiwyg",
			humanReadable: true,
			key: "subtitle",
			hideable: true,
		},
	],
	defaults: {
		contentTitle: { content: "Title", tag: "h2" },
		subtitle: "Lorem Ipsum",
	},
};

export default TitleAndSubtitle;
