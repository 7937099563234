import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Landing Hero Form",
	component: "LandingForm",
	category: "interactive",
	dataPacks: ["STUDY_LANDING"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Study Landing",
					type: "ReferenceField",
					sources: [{ structuredData: "STUDY_LANDING" }],
					key: "data",
				},
			],
		},
	],

	default: {
		component: "LandingForm",
		data: {
			mode: "auto",
			sources: [{ structuredData: "STUDY_LANDING" }],
			order: "alpha",
			quantity: 15,
		},
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/MultiproductHeroForm_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/MultiproductHeroForm_x2.png`,
	},
});
