import { SiteContext, PageContext } from "@contexts";
import { useContext, useState, useCallback } from "react";

/**
 * Este hook devuelve los datos de uno o varios content types. Pueden ser puros o de página.
 * Permite definir los campos a obtener y si queremos las relaciones.
 * WIP!!

 * @returns Array
 *
 * @example
 *   const staffPublicContentData = useContentTypeData({
 *     contentDataIDs: [235,534],
 *     fullRelations: false,
 *     fields: ['staffTitle', 'url'],
 *   })
 */

export const useContentTypeData = () => {
	const { publicApiUrl, siteId } = useContext(SiteContext);

	const { languageId } = useContext(PageContext);
	const [contentData, setContentData] = useState(null);

	const setContentTypeDataQuery = useCallback(
		({ contentDataIDs, fullRelations, fields }) => {
			const contentDataIDsArray = Array.isArray(contentDataIDs)
				? contentDataIDs
				: [contentDataIDs];
			if (contentDataIDsArray.length) {
				const urlBase = `${publicApiUrl}/list/v2/`; //8404,8405,8403,8406/site/29/lang/1/get/title/page/2/items/2/filter/8773
				const urlIds = `fixed/${contentDataIDsArray.join(",")}/`;
				const urlSite = siteId ? `site/${siteId}/` : `site/1/`; //Si no hay site, estamos editando una página global, y no importa el site que pongamos, pero hay que ponerlo.
				const urlLang = `lang/${languageId}/`;
				const urlFields =
					!!fields && !!fields.length ? `get/${fields.join(",")}` : ``;
				const urlRelations = fullRelations ? `relations/on/` : ``;
				const urlComplete = `${urlBase}${urlIds}${urlSite}${urlLang}${urlFields}${urlRelations}`;
				fetch(urlComplete)
					.then((response) => {
						if (response.ok) {
							return response.json();
						}
						throw new Error("Network response was not ok.");
					})
					.then((data) => {
						if (data?.items) {
							setContentData(data.items);
						}
					})
					// eslint-disable-next-line no-console
					.catch((error) => console.error("Error:", error));
			}
		},
		[]
	);

	return [contentData, setContentTypeDataQuery];
};
