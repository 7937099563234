import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Distributor",
	component: "Distributor",
	dataPacks: null,
	category: null,
	sectionList: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Items",
					type: "ReferenceField",
					sources: [{ structuredData: "ITEMS" }],
					key: "data",
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Anchor ID",
					type: "TextField",
					key: "anchorID",
					prefix: "#",
					hideable: true,
					helptext:
						"Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
				},
			],
		},
	],

	default: {
		component: "Distributor",
		hasDistributorData: true,
		data: {
			mode: "auto",
			sources: [{ structuredData: "ITEMS" }],
			order: "alpha",
			quantity: 2,
		},
	},

	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Distributor_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Distributor_x2.png`,
	},
});
