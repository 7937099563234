export const TYPE_SCHEMAS = {
	template: "template",
	module: "module",
	component: "component",
	dataPack: "dataPack",
};

const mountSchema = ({ schemaBase, fields, defaults }) => {
	const schema = { ...schemaBase };
	const schemaType = TYPE_SCHEMAS[schema.schemaType] || "dataPack";

	switch (schemaType) {
		case "template":
			schema.content = [...schema.content, ...fields];
			schema.default = Object.assign({}, schemaBase.default, defaults);
			break;
		case "module":
		case "component":
			schema.configTabs[0].fields = [...schema.configTabs[0].fields, ...fields];
			schema.default = Object.assign({}, schemaBase.default, defaults);
			break;
		case "dataPack":
			schema.schema.fields = [...schema.schema.fields, ...fields];
			schema.defaultValues = Object.assign(
				{},
				schemaBase.defaultValues,
				defaults
			);
			break;
	}

	return schema;
};

export default mountSchema;
