import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Accordion Element",
	component: "AccordionElement",
	dataPacks: null,
	category: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
					advanced: true,
					hideable: true,
				},
				{
					title: "Component",
					type: "ComponentArray",
					hideable: true,
					mandatory: true,
					whiteList: [
						"BasicContent",
						"CardCollection",
						"HorizontalTabs",
						"Table",
					],
					key: "componentModules",
				},
			],
		},
	],
	default: {
		component: "AccordionElement",
		title: { content: "Title", tag: "h3" },
		componentModules: [
			{
				component: "CardCollection",
				title: { content: "Title", tag: "h2" },
				subtitle: "Lorem Ipsum",
				kind: "BasicCard",
				elements: [
					{
						component: "BasicCard",
						title: "Lorem ipsum",
						description:
							"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
						file: {
							alt: "",
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							url: "",
						},
						veil: 0,
						button: {
							component: "Button",
							buttonText: "Link",
							appearance: "link",
							size: "medium",
							url: {
								href: "",
								linkTo: null,
								newTab: false,
								noFollow: false,
							},
						},
					},
				],
				button: {
					component: "Button",
					buttonText: "Link",
					appearance: "link",
					size: "small",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
				layout: "L001",
			},
		],
	},
	thumbnails: {},
});
