import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Quick Facts",
	component: "QuickFacts",
	dataPacks: null,
	category: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Quick Facts elements",
					type: "ComponentArray",
					maxItems: 5,
					whiteList: ["QuickFactsElement"],
					key: "elements",
				},
			],
		},
	],
	default: {
		component: "QuickFacts",
		elements: [
			{
				component: "QuickFactsElement",
				title: "Lorem Ipsum",
				description: "Lorem ipsum dolor ",
			},
		],
	},
	thumbnails: {},
});
