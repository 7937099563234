import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Study Distributor",
	component: "FormTypeStudyDistributor",
	dataPacks: ["LANDINGS"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Study Distributor",
					type: "ReferenceField",
					sources: [
						{ structuredData: "STUDY" },
						{ structuredData: "EXTERNAL_STUDY" },
					],
					key: "data",
				},
			],
		},
	],

	default: {
		component: "FormTypeStudyDistributor",
		data: {
			mode: "auto",
			sources: [
				{ structuredData: "STUDY" },
				{ structuredData: "EXTERNAL_STUDY" },
			],
			order: "alpha",
			allLanguages: true,
			preferenceLanguage: true,
			quantity: 0,
		},
		hasDistributorData: true,
	},
	thumbnails: {},
});
