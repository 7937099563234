export default {
	STUDY_LANDING: {
		title: "Study Landing",
		dataPacks: ["STUDY_LANDING"],
		local: true,
		taxonomy: false,
		fromPage: false,
		translate: false,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
				},
				{
					title: "Salesforce ID",
					type: "TextField",
					key: "salesforceID",
					from: "salesforceID",
				},
				{
					title: "Program type",
					type: "AsyncCheckGroup",
					source: "STUDY_LANDING_TYPE",
					key: "programType",
					from: "programType",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
