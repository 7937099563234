export default {
	schemaType: "template",
	displayName: "Job Offer List",
	component: "JobOfferList",
	dataPacks: ["JOB_OFFERS"],
	type: { label: "Job Offers", value: "JOB_OFFERS", mode: "list" },
	content: [
		{
			title: "Hero section",
			type: "ComponentContainer",
			whiteList: ["InnerTitle"],
			key: "innerTitleComp",
		},
		{
			title: "Job Offers",
			type: "ReferenceField",
			sources: [{ structuredData: "JOB_OFFERS" }],
			key: "data",
		},
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			mandatory: true,
		},

		//Desactivo globalOperator y filterOperator porque aun no se pueden editar en la API privada en AX
		// {
		//   title: 'Filtro entre categorías',
		//   type: 'Select',
		//   key: 'globalOperator',
		//   hideable: true,
		//   options: [
		//     { value: 'AND', label: 'Coincidir todos (AND)' },
		//     { value: 'OR', label: 'Coincidir alguno (OR)' },
		//   ],
		// },
		// {
		//   title: 'Filtro entre opciones de cada categoría',
		//   type: 'Select',
		//   key: 'filterOperator',
		//   hideable: true,
		//   options: [
		//     { value: 'AND', label: 'Coincidir todos (AND)' },
		//     { value: 'OR', label: 'Coincidir alguno (OR)' },
		//   ],
		// },
	],

	default: {
		type: "template",
		templateType: "JobOfferList",
		data: {
			mode: "auto",
			sources: [{ structuredData: "JOB_OFFERS" }],
			order: "jobOfferDate-DESC",
			quantity: 0,
		},
		hasDistributorData: true,
		innerTitleComp: {
			component: "InnerTitle",
			title: {
				content: "Title",
				tag: "h1",
			},
			subtitle: "Subtitle",
			backgroundImage: {
				component: "Image",
				alt: "Alt",
				file: {
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
				},
				veil: 0,
			},
			icon: false,
			breadcrumbsOnly: false,
			theme: "default",
			size: "small",
		},
		itemsPerPage: 4,
		// globalOperator: 'AND',
		// filterOperator: 'OR',
	},

	thumbnails: {},
};
