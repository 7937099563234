const BirthdayField = {
	content: {
		title: "Birthday field",
		type: "RadioGroup",
		key: "birthdayField",
		options: [
			{
				value: true,
				title: "Yes",
				name: "yes",
			},
			{
				value: false,
				title: "No",
				name: "no",
			},
		],
	},
	default: {
		birthdayField: false,
	},
};
export default BirthdayField;
