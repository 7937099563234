export default {
	BLOG_TAG: {
		title: "Blog Tag",
		dataPacks: ["BLOG"],
		local: false,
		taxonomy: false,
		fromPage: false,
		translate: true,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
					mandatory: true,
				},
				{
					title: "URL",
					type: "UrlField",
					advanced: true,
					key: "url",
					hideable: true,
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
