import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "FAQ Element",
	component: "FAQElement",
	dataPacks: null,
	category: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					default: { tag: "h4", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
					advanced: true,
					hideable: true,
				},
				{
					title: "Content",
					type: "RichText",
					key: "content",
					mandatory: true,
					hideable: true,
					humanReadable: true,
				},
			],
		},
	],
	default: {
		component: "FAQElement",
		title: { content: "Title", tag: "h4" },
		content:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
	},
	thumbnails: {},
});
