import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Agenda Element",
	component: "AgendaElement",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Start time",
					placeholder: "hh:mm",
					helptext: "hh:mm",
					type: "TextField",
					key: "startTime",
					mandatory: true,
				},
				{
					title: "Description",
					type: "TextField",
					key: "description",
					mandatory: true,
				},
			],
		},
	],
	default: {
		component: "AgendaElement",
		startTime: "00:00",
		description: "Lorem ipsum dolor sit amet",
	},
	thumbnails: {},
});
