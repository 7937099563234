import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Link",
	component: "Link",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Text",
					type: "TextField",
					key: "linkText",
					hedeable: false,
					mandatory: true,
				},
				{
					title: "URL",
					type: "UrlField",
					key: "href",
					advanced: true,
					hedeable: false,
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "Link",
		linkText: "Link",
		href: "",
		newTab: true,
		noFollow: false,
	},

	thumbnails: {},
});
