import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Study Agregator Card",
	component: "StudyAgregatorCard",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					mandatory: false,
				},
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					mandatory: false,
				},
				{
					title: "Component",
					type: "ComponentArray",
					hideable: true,
					mandatory: true,
					whiteList: ["StudyAgregatorElement"],
					key: "componentModules",
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							value: "default",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/style01.png`,
						},
						/* {
              value: 'accentLight',
              img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/style02.png`,
            },
            {
              value: 'lightGray',
              img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/style03.png`,
            },
            {
              value: 'DarkGray',
              img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/style04.png`,
            }, */
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "StudyAgregatorCard",

		theme: "default",

		title: "Lorem ipsum dolor sit amet",
		subtitle: "Lorem ipsum dolor",
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
		},
		componentModules: [
			{
				component: "StudyAgregatorElement",
				theme: "default",

				title: "Máster Executive en Asesoría Jurídica Empresarial",

				date: "Octubre 2022",
				site: "Presencial",
				time: "Full Time",
				credits: "90 ECTS",
				button: {
					component: "ButtonToTop",
					buttonText: "Me interesa",
					appearance: "primary",
					size: "medium",
				},
			},
			{
				component: "StudyAgregatorElement",

				theme: "default",

				title: "Máster Executive en Asesoría Jurídica Empresarial",

				date: "Octubre 2022",
				site: "Presencial",
				time: "Full Time",
				credits: "90 ECTS",
				button: {
					component: "ButtonToTop",
					buttonText: "Me interesa",
					appearance: "primary",
					size: "medium",
				},
			},
		],
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/RelatedPrograms_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/RelatedPrograms_x2.png`,
	},
});
