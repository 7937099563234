import createThemeStyles from "@schemas/commons/getStyles";
import CareerOpportunitiesCard from "@schemas/components/CareerOpportunitiesCard";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Career Opportunities",
	component: "CareerOpportunities",
	category: "collections",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
					advanced: true,
					hideable: true,
				},
				{
					title: "Detail",
					type: "RichText",
					key: "detail",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Cards",
					type: "ComponentArray",
					mandatory: true,
					maxItems: null,
					key: "elements",
					whiteList: ["CareerOpportunitiesCard"],
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "cardsTheme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", [
								"default",
								"defaultAlt",
							]),
						},
						{ ...createThemeStyles("eeg-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("ipam-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("iade-theme", ["default", "defaultAlt"]) },
						{
							...createThemeStyles("iade-es-theme", ["default", "defaultAlt"]),
						},
						{
							...createThemeStyles("uddi-theme", ["default", "defaultAlt"]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "CareerOpportunities",
		title: { content: "Title", tag: "h2" },
		detail: "Lorem Ipsum",
		elements: new Array(3).fill(CareerOpportunitiesCard.default),
		theme: "default",
		cardsTheme: "default",
	},

	thumbnails: {
		"1x": `/thumbnails/modules/CareerOpportunities/thumbnail_x1.png`,
		"2x": `/thumbnails/modules/CareerOpportunities/thumbnail_x2.png`,
	},
});
