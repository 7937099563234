export default {
	STAFF: {
		title: "Staff",
		dataPacks: ["STAFF"],
		local: false,
		taxonomy: false,
		fromPage: true,
		translate: true,
		schema: {
			templates: ["StaffDetail"],
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					from: "image",
				},
				{
					title: "Staff Title",
					type: "HeadingField",
					humanReadable: true,
					key: "staffTitle",
					default: { tag: "h1", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
						{ value: "p", label: "p" },
					],
					advanced: true,
					from: "staffTitle",
				},
				{
					title: "Course",
					type: "RichText",
					humanReadable: true,
					key: "course",
					from: "course",
				},
				{
					title: "Information",
					type: "TextField",
					humanReadable: true,
					key: "info",
					from: "info",
				},
				{
					title: "Email",
					type: "TextField",
					key: "email",
					from: "email",
				},
				{
					title: "Document Link",
					type: "UrlField",
					key: "documentLink",
					from: "documentLink",
				},
				{
					title: "Bio Title",
					type: "TextField",
					humanReadable: true,
					key: "bioTitle",
					from: "bioTitle",
				},
				{
					title: "Bio",
					type: "RichText",
					humanReadable: true,
					key: "bio",
					from: "bio",
				},
				{
					title: "Short Bio",
					type: "RichText",
					humanReadable: true,
					key: "shortBio",
					from: "shortBio",
				},
			],
			searchFrom: ["staffTitle", "course", "info"],
		},
		clone: null,
		defaultValues: null,
	},
};
