import { SOCIAL_ICONS_MAP } from "@constants/socialShareLinks";
import { getWindow } from "ssr-window";

const window = getWindow();

function createSchema(schema) {
	// bypass
	return schema;
}

const isBrowser = typeof window !== "undefined";

const getToken = () => {
	const appInfo = isBrowser && JSON.parse(localStorage.getItem("persist:app"));

	if (!appInfo) {
		return null;
	}

	let { token } = appInfo;
	token = JSON.parse(token);
	return { Authorization: `bearer ${token}` };
};

const getSiteID = () => {
	const appInfo =
		typeof isBrowser !== "undefined" && isBrowser
			? JSON.parse(localStorage.getItem("persist:root"))
			: null;

	if (!appInfo) {
		return 1;
	}

	let { sites } = appInfo;
	sites = JSON.parse(sites);
	const {
		currentSiteInfo: { id },
	} = sites;
	return id;
};

const getLang = () => isBrowser && parseInt(localStorage.getItem("langID"));

function parseSocials(socialsObject, map = SOCIAL_ICONS_MAP) {
	if (socialsObject) {
		const socialsMapped = [];
		Object.keys(socialsObject).forEach((key) => {
			if (map[key] && socialsObject[key].length) {
				socialsMapped.push({ icon: map[key], url: socialsObject[key] });
			}
		});
		return socialsMapped;
	}
	return null;
}

function subThemeIsEmpty(obj) {
	return !Object.keys(obj).length;
}

function addBreakpointAlias(theme) {
	theme.mediaquery &&
		theme.mediaquery.mediaqueries.forEach((mq) => {
			theme.breakpoints[mq.label] = mq.minWidth;
		});
	return { ...theme };
}

const getCookie = (name) => {
	if (typeof document !== "undefined") {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) {
			return parts.pop().split(";").shift();
		}
		if (parts.length > 2) {
			return parts.slice(1).map((cookie) => cookie.split(";").shift());
		}
	}
};

const getUTM = (url = document.URL, utmKeysPrefix = "utm_") => {
	if (!url) {
		return {};
	}
	const splitUrl = url.split("?");

	const response = {};

	if (splitUrl.length > 1) {
		splitUrl.shift(1);

		splitUrl
			.join("?")
			.split("&")
			.filter((item) => !!item && item.startsWith(utmKeysPrefix))
			.map((item) => item.split("="))
			.map(
				(item) =>
					item.length > 1 && (response[item[0]] = item.slice(1).join("="))
			);
	}

	const activeUtm = (sessionStorage.getItem("activeUtm") || "")
		.split(",")
		.filter((item) => !!item);

	for (const utm of activeUtm) {
		if (!response[utm]) {
			response[utm] = getCookie(utm) || "";
		}
	}
	return response;
};

const getGCLID = (url) => {
	if (!url) {
		return {};
	}

	const startIdx = url.indexOf("gclid=");

	if (startIdx === -1) {
		return getCookie("gclid") || "";
	}

	const gclid = url.substr(startIdx + 6);

	if (gclid.includes("#")) {
		return gclid.substr(0, gclid.indexOf("#"));
	}
	if (gclid.includes("&")) {
		return gclid.substr(0, gclid.indexOf("&"));
	}
	return gclid || getCookie("gclid") || "";
};

const toBase64 = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.onerror = (error) => {
			// eslint-disable-next-line no-console
			console.log("Error: ", error);
		};
	});
};

const getFileInfo = async (file) => {
	try {
		if (!file) {
			return null;
		}
		const { name, type: contentType } = file;
		const content = await toBase64(file);
		return {
			name,
			contentType,
			content: content.substring(content.indexOf("64,") + 3, content.length),
		};
	} catch {
		return null;
	}
};

const addDataLayer = (event) => {
	if (!window) {
		return;
	}
	if (!window.dataLayer) {
		window.dataLayer = [];
	}
	window.dataLayer.push(event);
};

/* This method has been created to deambiguate whenever two GA cookies
are set. The sort method puts the newest GA version before */
const getLatestGoogleAnalyticsCookie = (name = "_ga") => {
	const cookies = getCookie(name);
	if (Array.isArray(cookies)) {
		const cookiesSorter = (cookie1, cookie2) => {
			const gaRegex = /^GA([0-9]+)\.([0-9]+)\./;
			const regexResultsCookie1 = gaRegex.exec(cookie1);
			const regexResultsCookie2 = gaRegex.exec(cookie2);
			if (!regexResultsCookie1) {
				return 1;
			}
			if (!regexResultsCookie2) {
				return -1;
			}

			const [, majorCookie1, minorCookie1] = regexResultsCookie1;
			const [, majorCookie2, minorCookie2] = regexResultsCookie2;
			return (
				parseInt(majorCookie2) - parseInt(majorCookie1) ||
				parseInt(minorCookie2) - parseInt(minorCookie1) ||
				1
			);
		};

		return cookies
			.filter((cookie) => cookie.indexOf("GA") === 0)
			.sort(cookiesSorter)
			.shift();
	}
	return cookies;
};

// ---- generate random characters for leadIdForm ---- //

// IADE & IPAM - 38 characters
const dec2hex = (dec) => {
	return dec.toString(16).padStart(2, "0");
};

const generateId = () => {
	const arr = new Uint8Array(39 / 2);
	if (window) {
		window.crypto.getRandomValues(arr);
	}
	return Array.from(arr, dec2hex).join("");
};

// CEG WEB characters - 57 characters
const dec2hexCegWeb = (dec) => {
	return dec.toString(16).padStart(3, "0");
};

const generateIdCegWeb = () => {
	const arr = new Uint8Array(39 / 2);
	if (window) {
		window.crypto.getRandomValues(arr);
	}
	return Array.from(arr, dec2hexCegWeb).join("");
};

// CEG Landings characters
const dec2hexCegLandings = (dec) => {
	return dec.toString(16).padStart(4, "0");
};

const generateIdCegLandings = () => {
	const arr = new Uint8Array(39 / 2);
	if (window) {
		window.crypto.getRandomValues(arr);
	}
	return Array.from(arr, dec2hexCegLandings).join("");
};

const getPdfName = (url) => {
	const start = url.lastIndexOf("/");
	const end = url.indexOf(".pdf");
	return url.slice(start + 1, end);
};

export {
	addBreakpointAlias,
	createSchema,
	getLang,
	getSiteID,
	getToken,
	parseSocials,
	subThemeIsEmpty,
	getUTM,
	getFileInfo,
	toBase64,
	addDataLayer,
	getCookie,
	getLatestGoogleAnalyticsCookie,
	getGCLID,
	generateId,
	generateIdCegWeb,
	generateIdCegLandings,
	getPdfName,
};
