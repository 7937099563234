import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Footer",
	component: "Footer",
	type: "footer",
	category: "footer",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Name",
					type: "TextField",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Information",
					type: "RichText",
					key: "info",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
				{
					title: "Legal text",
					type: "TextField",
					key: "legalText",
					mandatory: true,
					humanReadable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "setAsDefault",
					options: [
						{
							title: "Set as Footer default",
						},
					],
				},
				{
					title: "Select Footer Menu",
					key: "footerMenu",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
				},
				{
					title: "Select Legal Menu",
					key: "legalMenu",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
				},
				{
					title: "Email",
					type: "TextField",
					key: "email",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "Footer",
		type: "footer",
		title: "Footer name",
		email: "example@email.com",
		info: "<p>Centro universitario privado de formación e investigación, adscrito a la Universidad Complutense de Madrid.</p><p>Especializado, desde hace más de 40 años, en el ámbito de la Administración y la Dirección de Empresas, la Economía, el Derecho y las Finanzas.</p>",
		button: {
			component: "Button",
			buttonText: "Contacta con nosotros",
			appearance: "primary",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		setAsDefault: true,
		footerMenu: null,
		legalMenu: null,
		legalText: "© 2019 GARRIGUES. Todos los derechos reservados",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Footer_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Footer_x2.png`,
	},
});
