import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Video Title",
	component: "VideoTitle",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "URL Video",
					type: "TextField",
					key: "url",
					mandatory: true,
				},
				{
					title: "Thumbnail",
					type: "ComponentContainer",
					whiteList: ["Image"],
					key: "thumbnail",
					helptext: "Recommended minimum image size: 941x529",
					hideable: true,
				},
				{
					title: "Title",
					type: "TextField",
					key: "title",
					humanReadable: true,
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					humanReadable: true,
				},
			],
		},
	],
	default: {
		component: "VideoTitle",
		title: "Lorem ipsum",
		subtitle:
			" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",

		thumbnail: {
			component: "Image",
			file: {
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
		},
		url: "",
	},
	thumbnails: {},
});
