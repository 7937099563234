import { ThemeProvider } from "@contexts";
import {
	CloudinaryBackgroundImage,
	CloudinaryImage,
	Component,
	getComponent,
	Page,
	Preview,
} from "@griddo/core";
import { useStyledSubTheme, useTheme } from "@hooks";
import componentSchemas from "@schemas/components";
import moduleSchemas from "@schemas/modules";
import templateSchemas from "@schemas/templates";
import { addBreakpointAlias, subThemeIsEmpty } from "@utils/index";
import * as React from "react";

const schemas = {
	...moduleSchemas,
	...componentSchemas,
	...templateSchemas,
};

const getTheme = (theme, subTheme) => {
	const mainTheme = useTheme();
	return theme === "default"
		? addBreakpointAlias(mainTheme)
		: addBreakpointAlias(subTheme);
};

const getComponentConfig = (props) => {
	const { component, theme } = props;

	const defaultProps = schemas[component]?.default;
	const subTheme = useStyledSubTheme({
		name: theme,
	});
	const mappedTheme = getTheme(theme, subTheme);
	const hasSubTheme = !subThemeIsEmpty(subTheme);

	const mappedProps = {
		...defaultProps,
		...props,
		// Ponemos theme a null porque hace interferencia con styled components
		// A este nivel de componente no hace falta pasarle theme.
		theme: null,
		moduleTheme: theme,
	};

	return {
		mappedProps,
		mappedTheme,
		hasSubTheme,
	};
};

const withProviderComponent = (component) => {
	// eslint-disable-next-line react/display-name
	return (props) => {
		const { mappedProps, mappedTheme, hasSubTheme } = getComponentConfig(props);

		const mappedComponent = React.createElement(component, mappedProps);

		return hasSubTheme ? (
			<ThemeProvider theme={mappedTheme}>{mappedComponent}</ThemeProvider>
		) : (
			mappedComponent
		);
	};
};

const mapLibrary = (lib) => {
	return Object.fromEntries(
		Object.entries(lib).map(([key, component]) => {
			return [key, withProviderComponent(component)];
		})
	);
};

export default getComponent;

export {
	mapLibrary,
	Component,
	Page,
	Preview,
	CloudinaryBackgroundImage,
	CloudinaryImage,
};
