const PrivacyPoliceFormType = {
	content: [
		{
			title: "Privacy policy",
			type: "ComponentArray",
			whiteList: ["FormTypePrivacyPolicy"],
			key: "privacyPolicy",
			mandatory: true,
		},
	],
	default: {
		privacyPolicy: [
			{
				component: "FormTypePrivacyPolicy",
				data: {
					mode: "auto",
					sources: [{ structuredData: "PRIVACY_POLICY" }],
					order: "alpha",
				},
				hasDistributorData: true,
			},
		],
	},
};
export default PrivacyPoliceFormType;
