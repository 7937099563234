/**
 * Warns!
 * Everything imported here is added to the final bundle.
 */

// React components, modules and templates
import { SiteProvider } from "@adapters";
import { generateAutomaticDimensions } from "@analytics";
import components from "@components/index";
import modules from "@modules/index";
import * as templates from "@templates/index";

// Providers

import browser from "../builder.browser";
import ssr from "../builder.ssr";

// Dimensions

// Wrapped components
const bundle = { ...components, ...modules };

export {
	bundle as components,
	templates,
	ssr,
	browser,
	generateAutomaticDimensions,
	SiteProvider,
};
