import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Event Card",
	component: "EventCard",
	dataPacks: ["EVENTS"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					from: "image",
				},
				{
					title: "Event Date",
					type: "DateField",
					key: "eventDate",
					from: "eventDate",
				},
				{
					title: "Event Address",
					type: "TextField",
					key: "eventAddress",
					from: "eventAddress",
				},
				{
					title: "Event Hour",
					placeholder: "hh:mm",
					helptext: "hh:mm",
					type: "TextField",
					key: "eventHour",
					from: "eventHour",
				},
				{
					title: "Event Type",
					type: "TextField",
					key: "eventType",
					from: "eventType",
				},
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "eventTitle",
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
					advanced: true,
					from: "eventTitle",
				},
				{
					title: "Description",
					type: "TextField",
					key: "description",
					from: "description",
				},
			],
		},
	],

	default: {
		component: "EventCard",
		eventDate: "2021/01/01",
		eventTitle: {
			content: "Event Card Title",
			tag: "h4",
		},
		eventAddress: "Presencial",
		eventType: "Presencial",
		eventHour: "00:00",
	},

	thumbnails: {},
});
