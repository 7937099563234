export default {
	DISCOUNTS: {
		title: "Discounts",
		dataPacks: ["DISCOUNTS"],
		local: true,
		taxonomy: false,
		fromPage: false,
		translate: true,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
				},
				{
					title: "Start Date",
					type: "DateField",
					key: "startDate",
					hideable: true,
				},
				{
					title: "End Date",
					type: "DateField",
					key: "endDate",
					hideable: true,
				},
				{
					title: "Discount text",
					type: "RichText",
					key: "text",
					mandatory: true,
					html: true,
				},
				{
					title: "Target pages",
					type: "ReferenceField",
					key: "target",
					sources: [
						{ structuredData: "INFO_LANDINGS" },
						{ structuredData: "STUDY" },
					],
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
