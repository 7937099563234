import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Cypher Card",
	component: "CypherCard",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Number",
					type: "TextField",
					key: "number",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Description",
					type: "TextField",
					key: "description",
					hideable: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "CypherCard",
		number: "0",
		subtitle: "Lorem ipsum",
		description: "Lorem ipsum dolor sit amet",
	},

	thumbnails: {},
});
