import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";

function createThemeStyles(theme, options = []) {
	const validOptions = [
		"default",
		"defaultAlt",
		"accent",
		"accentAlt",
		"accentLight",
	];

	const filteredOptions = options.filter((opt) => validOptions.includes(opt));

	return {
		theme: theme,
		options: filteredOptions.map((opt) => {
			return {
				value: opt,
				img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/${theme}/${opt}.png`,
			};
		}),
	};
}

export default createThemeStyles;
