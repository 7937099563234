import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Teacher Card",
	component: "TeacherCard",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					hideable: true,
				},
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Email",
					type: "TextField",
					key: "email",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Document Link",
					type: "UrlField",
					key: "documentLink",
					helptext: "Add link to a pdf or any document type",
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "TeacherCard",
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			url: "",
		},
		title: "Title",
		subtitle: "Lorem Ipsum",
		email: "example@email.com",
		documentLink: {
			text: "Descargar PDF",
			href: "",
			external: true,
			newTab: false,
			noFollow: false,
			linkTo: null,
		},
		button: {
			component: "Button",
			buttonText: "Ver perfil",
			appearance: "secondary",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		bg: "ui04",
	},
	thumbnails: {},
});
