import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

const isIPAM = instance.includes("IPAM");
const isIADE = instance.includes("IADE");
const isIADEES = instance.includes("iadeES");
const isCEG = instance.includes("CEG");

const defaultOptionsFilters = [
	{ value: "COURSE_MODE", name: "COURSE_MODE", title: "Course Mode" },
	{ value: "STUDY_TYPE", name: "STUDY_TYPE", title: "Program type" },
];

const extraOptionsFiltersIPAM_IADE = [
	{ value: "LOCATION", name: "LOCATION", title: "Location" },
	{ value: "LANGUAGE", name: "LANGUAGE", title: "Language" },
];

const optionsFiltersIADE = [
	...defaultOptionsFilters,
	...extraOptionsFiltersIPAM_IADE,
	{ value: "KNOWLEDGE_AREA", name: "KNOWLEDGE_AREA", title: "Knowledge Area" },
];

const optionsFiltersIPAM = [
	...defaultOptionsFilters,
	...extraOptionsFiltersIPAM_IADE,
];

const optionsFiltersCEG = [
	{
		value: "STUDY_TYPE",
		name: "STUDY_TYPE",
		title: "Program type",
	},
];

const optionsFilter = () => {
	if (isIPAM) {
		return optionsFiltersIPAM;
	}
	if (isIADE || isIADEES) {
		return optionsFiltersIADE;
	}
	if (isCEG) {
		return optionsFiltersCEG;
	}
	return defaultOptionsFilters;
};

export default createSchema({
	schemaType: "module",
	displayName: "Landings Study Distributor",
	component: "LandingStudyDistributor",
	category: "collections",
	dataPacks: [isCEG ? "STUDY" : "STUDY", "EXTERNAL_STUDY"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Description",
					type: "TextField",
					humanReadable: true,
					key: "description",
					mandatory: false,
				},
				{
					title: "Filters",
					type: "CheckGroup",
					key: "filters",
					hideable: true,
					options: optionsFilter(),
				},
				{
					title: "Quick Facts",
					type: "ComponentArray",
					whiteList: ["QuickFactsLandingsPortugal"],
					key: "quickFacts",
					maxItems: 1,
					mandatory: true,
				},
				{
					title: "Items to show",
					type: "NumberField",
					key: "itemsToShow",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", [
								"default",
								"defaultAlt",
							]),
						},
						{ ...createThemeStyles("eeg-theme", ["default"]) },
						{ ...createThemeStyles("ipam-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("iade-theme", ["default", "defaultAlt"]) },
						{
							...createThemeStyles("iade-es-theme", ["default", "defaultAlt"]),
						},
						{ ...createThemeStyles("uddi-theme", ["default", "defaultAlt"]) },
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "LandingStudyDistributor",
		title: { content: "Title", tag: "h2" },
		description: "Lorem Ipsum",
		quickFacts: [
			{
				component: "QuickFactsLandingsPortugal",
				data: {
					mode: "auto",
					sources: [{ structuredData: "QUICK_FACTS" }],
					order: "alpha",
				},
				hasDistributorData: true,
			},
		],
		theme: instance.includes("IPAM") ? "defaultAlt" : "default",
		filters: "",
		itemsToShow: 3,
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StudyDistributor_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StudyDistributor_x2.png`,
	},
});
