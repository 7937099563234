import { createSchema } from "@utils/";

export default createSchema({
	schemaType: "module",
	displayName: "Quick Facts",
	component: "QuickFactsLandingsPortugal",
	category: "collection",
	dataPacks: null,
	configTabs: [
		{
			title: "QuickFactsLandingsPortugal",
			fields: [
				{
					title: "Quick Facts",
					type: "ReferenceField",
					sources: [{ structuredData: "QUICK_FACTS" }],
					key: "data",
				},
			],
		},
	],

	default: {
		title: "QuickFactsLandingsPortugal",
		component: "QuickFactsLandingsPortugal",
		data: {
			mode: "auto",
			sources: [{ structuredData: "QUICK_FACTS" }],
			order: "alpha",
		},
		hasDistributorData: true,
	},

	thumbnails: {},
});
