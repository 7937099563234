import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Text block",
	component: "JobOfferTextBlock",
	category: "",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Description",
					type: "RichText",
					key: "description",
					hideable: true,
					humanReadable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "isListHorizontal",
					options: [{ title: "Show lists horizontal" }],
				},
			],
		},
	],
	default: {
		component: "JobOfferTextBlock",
		title: "Lorem ipsum",
		description:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
	},

	thumbnails: {},
});
