import { instance } from "@instance-config";

const isIADEES = instance.includes("iadeES");

const typeArea = [
	"AreaLanding",
	"ProductLanding",
	"GenericInfoLanding",
	"OtherInfoLanding",
	"mayores23",
	"enem",
	"mudanza",
];
const typeDual = ["DualAreaLanding", "DualProductLanding"];

/**
 * @type {{academicName: undefined, pageType: undefined, academicType: undefined, academicProductID: undefined, academicModality: undefined, academicDuration: undefined, academicECTs: undefined, academicCampus: undefined, academicStart: undefined, pageLanguage: undefined, url: undefined, academicLanguage: undefined}}
 *
 * @param {String} pageType - landingpage
 * @param {String} pageLanguage - Idioma de la página
 * @param {String} academicCampus - Es el campus donde se imparte el estudio mostrado
 *    - online
 *    - porto
 *    - lisboa
 * @param {String} academicType - Tipología de estudio. En el caso de que sea una combinación de varios, se concatenan separados de guión medio (-)
 *   - licenciaturas
 *   - mestrados
 *   - formacao executivos
 *   - ctesp
 * @param {String} academicName - Nombre oficial del programa al que se refiere la ficha de producto.
 *   - grado en ingenieria de edificacion
 *   - doble grado en farmacia y biotecnologia
 *   - ...
 * @param {String} academicModality - Modalidad de estudio
 *  - presencial
 *  - online
 * @param {String} academicECTs - Los créditos de la titulación
 * @param {String} academicStart - Fecha de inicio del estudio
 * @param {String} academicDuration - Duración del estudio
 * @param {String} academicLanguage - Idioma de la página.
 *  - portugues
 *  - ingles
 * @param {String} academicProductID - Identificador alfanumérico del tipo de estudio seleccionado, debe ser el mismo que el del CRM.
 * @param {String} url -  URL sin ningún tipo de parámetro añadido (utms, búsquedas en el sitio web, aplicación de filtros en la página de listado)
 */
const BASE_INFO_LANDINGS = {
	pageType: "landingpage",
	pageLanguage: undefined,
	academicCampus: undefined,
	academicType: undefined,
	academicName: undefined,
	academicModality: undefined,
	academicECTs: undefined,
	academicStart: undefined,
	academicDuration: undefined,
	academicLanguage: undefined,
	academicProductID: undefined,
	url: undefined,
};

/**
 *
 * @param arr - Array objects
 * @param key - Key to extract
 * @param isObject - If the value is an object
 * @param separator - Separator to join the values
 *
 * @returns {String} - String with the values
 */
const extractValues = ({ arr, key, isObject, separator = "" }) => {
	return arr
		?.map(({ content }) => {
			if (isObject) {
				return (
					(content[key]?.length
						? content[key][0]?.label
						: content[key]?.label) || ""
				);
			}
			return content[key] || "";
		})
		.filter((str, index, arr) => str && arr.indexOf(str) === index)
		.join(separator);
};

const getAcademicModality = (study) => {
	if (study?.length === 1) {
		if (study[0]?.content.courseMode?.label) {
			return study[0]?.content.courseMode?.label || "";
		}

		if (study[0]?.content.courseMode?.length > 0) {
			return study[0]?.content.courseMode[0]?.label || "";
		}
	}

	return "todos";
};

/**
 *
 * @param formType - Template type
 * @param study - Study data (array of objects)
 * @param dualLanding - If the landing is dual
 * @param currentLang - Current language
 * @param fullUrl - Full url
 * @param window - Window object
 *
 * @returns Object
 */
export const pushDataLayerInfoLanding = ({
	formType = "",
	study = [],
	currentLang,
	fullUrl,
	window,
	academicRecognition,
	academicQualification,
}) => {
	const _datalayer = Object.assign({ ...BASE_INFO_LANDINGS });
	_datalayer.pageLanguage = currentLang;
	_datalayer.academicLanguage = currentLang;
	_datalayer.url = fullUrl;

	_datalayer.academicRecognition = academicRecognition || null;
	_datalayer.academicQualification = academicQualification || null;

	try {
		if (typeArea.includes(formType)) {
			_datalayer.academicCampus =
				study?.length === 1
					? (study[0].content.location?.length &&
							study[0].content.location[0]?.label) ||
					  ""
					: "todos";

			_datalayer.academicType =
				study?.length === 1
					? (study[0].content.programType?.length &&
							study[0].content.programType[0]?.label) ||
					  ""
					: "todos";

			_datalayer.academicName =
				study?.length === 1 ? study[0].content?.programTitle || "" : "todos";

			_datalayer.academicModality = getAcademicModality(study);

			_datalayer.academicECTs =
				study?.length === 1 ? study[0]?.content.credits || "" : "todos";

			_datalayer.academicStart =
				study?.length === 1 ? study[0]?.content.start || "" : "todos";

			_datalayer.academicDuration =
				study?.length === 1 ? study[0]?.content.dedication || "" : "todos";

			_datalayer.academicProductID =
				study?.length === 1 ? study[0]?.content.salesforceID || "" : "todos";

			if (isIADEES) {
				const areas = {
					"Diseño de interiores": "diseno de interiores",
					"Diseño de moda": "moda",
				};
				_datalayer.academicArea =
					study?.length === 1
						? study[0]?.content.knowledgeArea
								?.map((area) => areas[area.label] || area.label)
								.join(", ")
						: "todos";
			}
		}

		if (typeDual.includes(formType) && study?.length > 0) {
			_datalayer.academicCampus = extractValues({
				arr: study,
				key: "location",
				isObject: true,
				separator: "|",
			});

			_datalayer.academicType = `DUAL ${extractValues({
				arr: study,
				key: "programType",
				isObject: true,
				separator: "-",
			})}`;

			_datalayer.academicName = `DUAL ${extractValues({
				arr: study,
				key: "programTitle",
				isObject: false,
				separator: "-",
			})}`;

			_datalayer.academicModality = `DUAL ${extractValues({
				arr: study,
				key: "courseMode",
				isObject: true,
				separator: "|",
			})}`;

			_datalayer.academicECTs = extractValues({
				arr: study,
				key: "credits",
				isObject: false,
				separator: "|",
			});

			_datalayer.academicStart = extractValues({
				arr: study,
				key: "start",
				isObject: false,
				separator: "|",
			});

			_datalayer.academicDuration = extractValues({
				arr: study,
				key: "dedication",
				isObject: false,
				separator: "|",
			});

			_datalayer.academicProductID = extractValues({
				arr: study,
				key: "salesforceID",
				isObject: false,
				separator: "|",
			});

			if (isIADEES) {
				_datalayer.academicArea = extractValues({
					arr: study,
					key: "knowledgeArea",
					isObject: true,
					separator: "|",
				});
			}
		}

		// NOTE: SET COURSE MODE AND PROGRAM TYPE INTO SESSION STORAGE TO CTA BUTTON
		if (typeof window !== "undefined") {
			window?.sessionStorage?.setItem("pageType", "infoLanding");
			window?.sessionStorage?.setItem(
				"eventAction",
				_datalayer.academicModality
			);
			window?.sessionStorage?.setItem("eventLabel", _datalayer.academicName);
		}
	} catch (e) {
		/* eslint-disable no-console */
		console.group("ERROR");
		console.log({
			fullUrl,
			formType,
			study: JSON.stringify(study),
		});
		console.groupEnd();
	}

	return _datalayer;
};
