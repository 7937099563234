import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Link Card",
	component: "LinkCard",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					mandatory: true,
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "LinkCard",
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
		},
		veil: 0,
		button: {
			component: "Button",
			buttonText: "Link",
			appearance: "secondary",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
	},

	thumbnails: {},
});
