import thumbnail from "@axthumbs/modules/FormTypeOtherInfoLanding.png";
import { instance } from "@instance-config";
import FormFields from "@schemas/commons/FormFields";
import PrivacyPoliceFormType from "@schemas/commons/PrivacityPoliceFormType";
import ShowDefaultMethodOfAdmission from "@schemas/commons/ShowDefaultMethodOfAdmission";
import ShowSelectorBachelorDegree from "@schemas/commons/ShowSelectorBachelorDegree";
import StudyLandingDistributor from "@schemas/commons/StudyLandingDistributor";
import TitleAndSubtitle from "@schemas/commons/TitleAndSubtitle";
import { createSchema } from "@utils/";

const optionsTypeForm = [
	{ value: "mayores23", label: "Mayores 23" },
	{ value: "mudanza", label: "Mudanza" },
];

const optionsTypeFormIPAM = [
	...optionsTypeForm,
	{ value: "enem", label: "ENEM" },
];

export default createSchema({
	schemaType: "module",
	displayName: "Other Info Landing",
	component: "FormTypeOtherInfoLanding",
	category: "FormType",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				...TitleAndSubtitle.content,
				{
					title: "Tipo",
					type: "Select",
					key: "formType",
					options: instance.includes("IPAM")
						? optionsTypeFormIPAM
						: optionsTypeForm,
					mandatory: true,
				},
				{
					...ShowDefaultMethodOfAdmission.content,
				},
				{
					...ShowSelectorBachelorDegree.content,
				},
				{
					...StudyLandingDistributor.content,
				},
				...PrivacyPoliceFormType.content,
				...FormFields.content,
			],
		},
	],
	default: {
		title: "mayores23",
		component: "FormTypeOtherInfoLanding",
		...TitleAndSubtitle.defaults,
		...ShowSelectorBachelorDegree.default,
		data: {
			...StudyLandingDistributor.default,
			mode: "auto",
		},
		hasDistributorData: true,
		formType: "mayores23",
		formPosition: "right",
		...ShowDefaultMethodOfAdmission.default,
		...PrivacyPoliceFormType.default,
		...FormFields.defaults,
	},
	thumbnails: {
		"1x": thumbnail,
		"2x": thumbnail,
	},
});
