import thumbnail from "@axthumbs/modules/FormTypeGenericInfoLanding.png";
import FormFields from "@schemas/commons/FormFields";
import PrivacyPoliceFormType from "@schemas/commons/PrivacityPoliceFormType";
import ShowSelectorBachelorDegree from "@schemas/commons/ShowSelectorBachelorDegree";
import StudyLandingDistributor from "@schemas/commons/StudyLandingDistributor";
import TitleAndSubtitle from "@schemas/commons/TitleAndSubtitle";
import { createSchema } from "@utils/";

export default createSchema({
	schemaType: "module",
	displayName: "Generic Info Landing",
	component: "FormTypeGenericInfoLanding",
	category: "FormType",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				...TitleAndSubtitle.content,
				{
					...ShowSelectorBachelorDegree.content,
				},
				{
					...StudyLandingDistributor.content,
				},
				...PrivacyPoliceFormType.content,
				...FormFields.content,
			],
		},
	],
	default: {
		title: "GenericInfoLanding",
		component: "FormTypeGenericInfoLanding",
		...TitleAndSubtitle.defaults,
		theme: "default",
		formType: "GenericInfoLanding",
		...ShowSelectorBachelorDegree.default,
		data: {
			...StudyLandingDistributor.default,
			mode: "auto",
		},
		hasDistributorData: true,
		...PrivacyPoliceFormType.default,
		...FormFields.defaults,
	},
	thumbnails: {
		"1x": thumbnail,
		"2x": thumbnail,
	},
});
