import mountStudySchema from "@schemas/commons/Study";

const schemaBase = {
	title: "External study",
	dataPacks: ["STUDY"],
	local: false,
	taxonomy: false,
	fromPage: false,
	translate: true,
	schema: {
		fields: [
			{
				title: "Program Title",
				type: "TextField",
				humanReadable: true,
				key: "title",
				mandatory: true,
			},
		],
	},
	clone: null,
	defaultValues: {},
};
const EXTERNAL_STUDY = {
	EXTERNAL_STUDY: mountStudySchema({ schemaBase }),
};
export default EXTERNAL_STUDY;
