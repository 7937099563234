import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "FooterLandingSimple",
	component: "FooterLandingSimple",
	type: "footer",
	category: "footer",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title to Save",
					type: "TextField",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Legal text",
					type: "TextField",
					key: "legalText",
					mandatory: true,
					humanReadable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "setAsDefault",
					options: [
						{
							title: "Set as Footer default",
						},
					],
				},
				{
					title: "Select Legal Menu",
					key: "legalMenu",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "FooterLandingSimple",
		type: "footer",
		title: "Footer name",
		setAsDefault: true,
		legalMenu: null,
		legalText: "© 2022 GARRIGUES. Todos los derechos reservados",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/FooterLandingSimple.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/FooterLandingSimple_x2.png`,
	},
});
