import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Quick Facts Element",
	component: "QuickFactsElement",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "title",
					type: "TextField",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Description",
					type: "TextField",
					key: "description",
					mandatory: true,
					humanReadable: true,
				},
			],
		},
	],
	default: {
		component: "QuickFactsElement",
		title: "Lorem ipsum ",
		description: "Lorem ipsum dolor sit amet",
	},
	thumbnails: {},
});
