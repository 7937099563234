import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Header Landing",
	component: "HeaderLanding",
	type: "header",
	category: "header",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Name",
					key: "title",
					type: "TextField",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Phone",
					type: "TextField",
					key: "phoneNumber",
					mandatory: true,
				},
				{
					title: "ButtonToTop",
					type: "ComponentContainer",
					whiteList: ["ButtonToTop"],
					key: "button",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "setAsDefault",
					options: [{ title: "Set as Header default" }],
				},
				{
					title: "Main menu",
					type: "AsyncSelect",
					entity: "menu_containers",
					key: "mainMenu",
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "HeaderLanding",
		type: "header",
		title: "Header Landing",
		note01: {
			title: "",
			text: "To configure social links go to settings/general/social. To activate search feature go to settings/actionables",
		},
		phoneNumber: "+34 91 514 53 30",
		button: {
			component: "ButtonToTop",
			buttonText: "Inscríbete",
			appearance: "primary",
			size: "medium",
		},
		setAsDefault: true,
		mainMenu: null,
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HeaderLanding_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HeaderLanding_x2.png`,
	},
});
