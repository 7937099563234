export default {
	LANDING_TYPE: {
		title: "Tipología de Landing",
		dataPacks: ["LANDINGS", "DISCOUNTS", "MODALS"],
		local: true,
		taxonomy: false,
		fromPage: false,
		translate: false,
		editable: false,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
				},
				{
					title: "Code",
					type: "TextField",
					key: "code",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
