export default {
	schemaType: "template",
	displayName: "Word List",
	component: "WordList",
	type: { label: "Word", value: "word", mode: "list" },
	content: [
		{
			title: "Main Title",
			type: "HeadingField",
			humanReadable: true,
			key: "mainTitle",
		},
		{
			title: "Subtitle",
			type: "TextField",
			humanReadable: true,
			key: "subtitle",
		},
		{
			title: "Word",
			type: "ReferenceField",
			mode: "auto",
			sources: [{ structuredData: "WORD" }],
			key: "data",
			order: "alpha",
			hideable: true,
		},
	],
	default: {
		type: "template",
		templateType: "WordList",
		mainTitle: { content: "Title", tag: "h1" },
		subtitle: "",
		alphabet: "abcdefghijklmnopqrstuvwxyz",
		word: [],
		button: [],
		hasDistributorData: true,
		data: {
			mode: "auto",
			sources: [{ structuredData: "WORD" }],
			order: "recent-ASC",
			fullRelations: true,
		},
		WordCategory: {
			mode: "auto",
			sources: [{ structuredData: "WORD_CATEGORY" }],
			order: "recent-ASC",
			fullRelations: true,
		},
	},
};
