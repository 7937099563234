export default {
	TESTIMONIALS: {
		title: "Testimonials",
		dataPacks: ["TESTIMONIALS"],
		local: false,
		taxonomy: false,
		fromPage: false,
		translate: true,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
					from: "title",
					mandatory: true,
				},
				{
					title: "Name",
					type: "TextField",
					humanReadable: true,
					key: "name",
					from: "name",
				},
				{
					title: "Quote",
					type: "TextField",
					humanReadable: true,
					key: "quote",
					from: "quote",
				},
				{
					title: "Position",
					type: "RichText",
					humanReadable: true,
					key: "position",
					from: "position",
				},
				{
					title: "Promotion",
					type: "RichText",
					humanReadable: true,
					key: "promotion",
					from: "promotion",
				},
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					from: "image",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
