import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Video",
	component: "Video",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "URL Video",
					type: "TextField",
					key: "url",
					mandatory: true,
				},
				{
					title: "Thumbnail",
					type: "ComponentContainer",
					whiteList: ["Image"],
					key: "thumbnail",
					hideable: true,
				},
			],
		},
	],
	default: {
		component: "Video",
		thumbnail: {
			component: "Image",
			file: {
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
		},
		url: "",
	},
	thumbnails: {},
});
