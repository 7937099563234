import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Study Agregator Element",
	component: "StudyAgregatorElement",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Description",
					type: "TextField",
					key: "description",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Date",
					type: "TextField",
					key: "date",
					mandatory: false,
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Site",
					type: "TextField",
					key: "site",
					mandatory: false,
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Time",
					type: "TextField",
					key: "time",
					mandatory: false,
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Credits",
					type: "TextField",
					key: "credits",
					mandatory: false,
					hideable: true,
					humanReadable: true,
				},
				{
					title: "ButtonToTop",
					type: "ComponentContainer",
					whiteList: ["ButtonToTop"],
					key: "button",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							value: "default",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/style01.png`,
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "StudyAgregatorElement",

		theme: "default",

		title: "Lorem ipsum dolor sit amet",
		description: "Lorem ipsum dolor sit amet",
		date: "Lorem ipsum dolor",
		site: "Lorem ipsum dolor",
		time: "Lorem ipsum dolor",
		credits: "Lorem ipsum dolor",
		button: {
			component: "ButtonToTop",
			buttonText: "Me interesa",
			appearance: "primary",
			size: "medium",
		},
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/RelatedPrograms_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/RelatedPrograms_x2.png`,
	},
});
