import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Study Card",
	component: "StudyCard",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Program Title",
					type: "TextField",
					key: "programTitle",
					mandatory: true,
					helptext: "Structured data",
					humanReadable: true,
				},
				{
					title: "Description",
					type: "TextField",
					key: "description",
					mandatory: true,
					helptext: "Structured data",
				},
				{
					title: "Learning option",
					type: "RichText",
					key: "learningOption",
					mandatory: true,
					helptext: "Structured data (online/in person)",
					humanReadable: true,
				},
				{
					title: "Dedication",
					type: "RichText",
					key: "dedication",
					mandatory: true,
					helptext: "Structured data (full time/part time)",
					humanReadable: true,
				},
				{
					title: "Credits",
					type: "RichText",
					key: "credits",
					mandatory: true,
					helptext: "Structured data",
					humanReadable: true,
				},
				{
					title: "Start",
					type: "RichText",
					key: "start",
					mandatory: true,
					helptext: "Structured data",
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "StudyCard",
		programTitle: "Lorem Ipsum",
		description: "Lorem Ipsum",
		learningOption: "Lorem Ipsum",
		dedication: "Lorem Ipsum",
		credits: "Lorem Ipsum",
		start: "Lorem Ipsum",
	},

	thumbnails: {},
});
