import { instance } from "@instance-config";

import Accordion from "./Accordion";
import AddressCollection from "./AddressCollection";
import AwardsDistributor from "./AwardsDistributor";
import BasicContent from "./BasicContent";
import BlogCategoriesMenu from "./BlogCategoriesMenu";
import BlogDistributor from "./BlogDistributor";
import CardCollection from "./CardCollection";
import CareerOpportunities from "./CareerOpportunities";
import CarouselSlide from "./CarouselSlide";
import CypherCollection from "./CypherCollection";
import Distributor from "./Distributor";
import DownloadBox from "./DownloadBox";
import EventAgenda from "./EventAgenda";
import EventDistributor from "./EventDistributor";
import EventHeroForm from "./EventHeroForm";
import FAQ from "./FAQ";
import FeaturedAudiovisual from "./FeaturedAudiovisual";
import FeaturedBlock from "./FeaturedBlock";
import FeaturedImage from "./FeaturedImage";
import Footer from "./Footer";
import FooterLanding from "./FooterLanding";
import FooterLandingSimple from "./FooterLandingSimple";
import FormTypeAreaLanding from "./FormTypeAreaLanding";
import FormTypeDualAreaLanding from "./FormTypeDualAreaLanding";
import FormTypeDualProductLanding from "./FormTypeDualProductLanding";
import FormTypeEasyPack from "./FormTypeEasyPack";
import FormTypeGenericInfoLanding from "./FormTypeGenericInfoLanding";
import FormTypeMultievent from "./FormTypeMultievent";
import FormTypeMultiProduct from "./FormTypeMultiProduct";
import FormTypeOtherInfoLanding from "./FormTypeOtherInfoLanding";
import FormTypeProductLanding from "./FormTypeProductLanding";
import FormTypeTMG from "./FormTypeTMG";
import FormTypeWebinar from "./FormTypeWebinar";
import GroupingCollection from "./GroupingCollection";
import Header from "./Header";
import HeaderLanding from "./HeaderLanding";
import HeaderOneLine from "./HeaderOneLine";
import HeaderTwoLines from "./HeaderTwoLines";
import HeroCard from "./HeroCard";
import HeroEcommerce from "./HeroEcommerce";
import HeroHome from "./HeroHome";
import HeroLanding from "./HeroLanding";
import HeroStudy from "./HeroStudy";
import HighlightedCarousel from "./HighlightedCarousel";
import HighLightedCTA from "./HighLightedCTA";
import HighlightedDegrees from "./HighlightedDegrees";
import HighlightedDownload from "./HighlightedDownload";
import HorizontalTabs from "./HorizontalTabs";
import InnerTitle from "./InnerTitle";
import Introduction from "./Introduction";
import JobOfferDistributor from "./JobOfferDistributor";
import JobOfferTextBlock from "./JobOfferTextBlock";
import JobOfferTextBlockWithIcon from "./JobOfferTextBlockWithIcon";
import LandingEventList from "./LandingEventList";
import LandingForm from "./LandingForm";
import LandingIntroduction from "./LandingIntroduction";
import LandingStudyDistributor from "./LandingStudyDistributor";
import MultiBrandHeader from "./MultiBrandHeader";
import MultiproductHeroForm from "./MultiproductHeroForm";
import NewsDistributor from "./NewsDistributor";
import Overview from "./Overview";
import OverviewEcommerce from "./OverviewEcommerce";
import ProductHeroForm from "./ProductHeroForm";
import ProgramBox from "./ProgramBox";
import QuickFactsLandingsPortugal from "./QuickFactsLandingsPortugal";
import RelatedPrograms from "./RelatedPrograms";
import SocialShare from "./SocialShare";
import SponsorsLogos from "./SponsorsLogos";
import SponsorsLogosLightbox from "./SponsorsLogosLightbox";
import StaffBio from "./StaffBio";
import StaffCarouselLanding from "./StaffCarouselLanding";
import StaffDistributor from "./StaffDistributor";
import StickyFooterCTA from "./StickyFooterCTA";
import StudyAgregator from "./StudyAgregator";
import StudyDistributor from "./StudyDistributor";
import StudyStaff from "./StudyStaff";
import Table from "./Table";
import Testimonials from "./Testimonials";
import TestimonialsLanding from "./TestimonialsLanding";
import VerticalCardCollection from "./VerticalCardCollection";
import VideoPlaylist from "./VideoPlaylist";

export default {
	Accordion,
	AddressCollection,
	AwardsDistributor,
	BasicContent,
	BlogCategoriesMenu,
	BlogDistributor,
	CardCollection,
	CareerOpportunities,
	CarouselSlide,
	CypherCollection,
	Distributor,
	DownloadBox,
	EventAgenda,
	EventDistributor,
	EventHeroForm,
	FAQ,
	FeaturedAudiovisual,
	FeaturedBlock,
	FeaturedImage,
	Footer,
	FooterLanding,
	FooterLandingSimple,
	FormTypeAreaLanding,
	FormTypeDualAreaLanding,
	FormTypeDualProductLanding,
	FormTypeEasyPack,
	FormTypeGenericInfoLanding,
	FormTypeMultievent,
	FormTypeMultiProduct,
	FormTypeOtherInfoLanding,
	FormTypeProductLanding,
	FormTypeTMG,
	FormTypeWebinar,
	GroupingCollection,
	Header,
	HeaderLanding,
	HeaderOneLine,
	HeaderTwoLines,
	HeroCard,
	HeroEcommerce,
	HeroHome,
	HeroLanding,
	HeroStudy,
	HighlightedCarousel,
	HighLightedCTA,
	HighlightedDegrees,
	HighlightedDownload,
	HorizontalTabs,
	InnerTitle,
	Introduction,
	JobOfferDistributor,
	JobOfferTextBlock,
	JobOfferTextBlockWithIcon,
	LandingEventList,
	LandingForm,
	LandingIntroduction,
	LandingStudyDistributor,
	MultiBrandHeader,
	MultiproductHeroForm,
	NewsDistributor,
	Overview,
	OverviewEcommerce,
	ProductHeroForm,
	ProgramBox,
	QuickFactsLandingsPortugal,
	RelatedPrograms,
	SocialShare,
	SponsorsLogos,
	SponsorsLogosLightbox,
	StaffBio,
	StaffCarouselLanding,
	StaffDistributor,
	StickyFooterCTA,
	StudyAgregator,
	StudyDistributor,
	StudyStaff,
	Table,
	Testimonials,
	TestimonialsLanding,
	VerticalCardCollection,
	VideoPlaylist,
};

export const excludedFromEEGmoduleList = [
	"AwardsDistributor",
	"EventDistributor",
	"HighlightedDegrees",
	"HighlightedDownload",
	"Overview",
	"ProgramBox",
	"RelatedPrograms",
	"StaffBio",
	"StaffDistributor",
	"StudyAgregator",
	"StudyDistributor",
];

export const onlyCEG = ["BlogDistributor", "JobOfferDistributor"];

export const basicHeroList = [
	"HeroHome",
	"InnerTitle",
	...(instance.includes("EEG") ? [] : ["HeroStudy"]),
];

export const basicModuleList = [
	"Accordion",
	"AddressCollection",
	"BasicContent",
	"CardCollection",
	"CareerOpportunities",
	"CypherCollection",
	"DownloadBox",
	"FAQ",
	"FeaturedAudiovisual",
	"FeaturedBlock",
	"FeaturedImage",
	"HighlightedCarousel",
	"HighLightedCTA",
	"HorizontalTabs",
	"Introduction",
	"NewsDistributor",
	"SocialShare",
	"SponsorsLogos",
	"StaffCarouselLanding",
	"StickyFooterCTA",
	"StudyStaff",
	"Table",
	"Testimonials",
	"VerticalCardCollection",
	"VideoPlaylist",
	...(!instance.includes("EEG") ? excludedFromEEGmoduleList : []),
	...(instance.includes("CEG") ? onlyCEG : []),
];

export const additionalModuleList = [
	"EventHeroForm",
	"LandingForm",
	"MultiproductHeroForm",
	"ProductHeroForm",
	"TestimonialsLanding",
];

export const landingModuleList = [
	"LandingStudyDistributor",
	...basicModuleList,
];
