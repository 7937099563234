const InternationalExtendedInformation = {
	content: {
		title: "International Extended Information",
		type: "RadioGroup",
		key: "InternationalExtendedInformation",
		options: [
			{
				value: true,
				title: "Yes",
				name: "yes",
			},
			{
				value: false,
				title: "No",
				name: "no",
			},
		],
	},

	default: {
		InternationalExtendedInformation: false,
	},
};
export default InternationalExtendedInformation;
