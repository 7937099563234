import { instance } from "@instance-config";
import { createSchema } from "@utils/";

const isIADE = instance.includes("IADE");

export default createSchema({
	schemaType: "component",
	displayName: "Image Card",
	component: "ImageCard",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					mandatory: true,
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
				},
				{
					title: "Name",
					type: "TextField",
					humanReadable: true,
					key: "name",
					mandatory: !isIADE,
				},
				{
					title: "Short Info",
					type: "TextField",
					humanReadable: true,
					key: "shortInfo",
					mandatory: !isIADE,
				},
				{
					title: "Degree",
					type: "TextField",
					humanReadable: true,
					key: "degree",
					mandatory: !isIADE,
				},
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
					mandatory: !isIADE,
				},
				{
					title: "Description",
					type: "TextField",
					humanReadable: true,
					key: "description",
					mandatory: !isIADE,
				},
			],
		},
	],

	default: {
		component: "ImageCard",
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
		},
		veil: 40,
		name: "Lorem Ipsum",
		shortInfo: "Lorem Ipsum",
		degree: "Lorem ipsum dolor sit amet consectetur",
		title:
			"Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus.",
		description:
			"“ELorem ipsum dolor sit amet consectetur adipiscing elit hac vivamus, vel senectus ligula nostra dapibus ultrices accumsan est, metus a eleifend sociosqu quis urna id feugiat. Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus, vulputate sociis cras quisque sagittis quis scelerisque curae orci hac lobortis, ut pellentesque vehicula non nunc viverra malesuada porta torquent. Dapibus vestibulum purus ullamcorper parturient fermentum arcu phasellus netus egestas vel viverra, penatibus interdum semper dis himenaeos diam senectus justo laoreet dignissim, facilisis est aptent ante fringilla maecenas libero rhoncus praesent quam.”",
	},

	thumbnails: {},
});
