import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Testimonials Landing",
	component: "TestimonialsLanding",
	category: "collections",
	dataPacks: ["TESTIMONIALS"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "manualTitle",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Testimonials Landing",
					type: "ReferenceField",
					sources: [{ structuredData: "TESTIMONIALS" }],
					key: "data",
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Layout",
					key: "layout",
					type: "VisualUniqueSelection",
					options: [
						{
							value: "L001",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/Testimonials/layout001_x2.png`,
						},
					],
					columns: 2,
				},
			],
		},
	],

	default: {
		component: "TestimonialsLanding",
		title: { content: "Title", tag: "h2" },
		data: {
			mode: "auto",
			sources: [{ structuredData: "TESTIMONIALS" }],
			order: "alpha",
			quantity: 15,
		},
		button: {
			component: "Button",
			buttonText: "Link",
			appearance: "link",
			size: "small",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		layout: "L001",
		hasDistributorData: true,
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Testimonials_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Testimonials_x2.png`,
	},
});
