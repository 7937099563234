export default {
	schemaType: "template",
	displayName: "Error 404",
	component: "Error404",
	dataPacks: null,
	type: { label: "Static", value: "static", special: "404" },
	singleInstance: true,
	content: [
		{
			title: "Background Image",
			type: "ComponentContainer",
			whiteList: ["Image"],
			key: "backgroundImage",
			hideable: true,
			helptext: "Recommended minimum image size: 2508x1030",
		},
		{
			title: "Error",
			type: "TextField",
			humanReadable: true,
			key: "error",
			mandatory: true,
			hideable: true,
		},
		{
			title: "Title",
			type: "RichText",
			humanReadable: true,
			key: "title",
			mandatory: true,
			hideable: true,
		},
		{
			title: "Subtitle",
			type: "RichText",
			humanReadable: true,
			key: "subtitle",
			mandatory: true,
			hideable: true,
		},
		{
			title: "Button",
			type: "ComponentContainer",
			whiteList: ["Button"],
			key: "button",
			hideable: true,
		},
	],

	default: {
		type: "template",
		templateType: "Error404",
		backgroundImage: {
			component: "Image",
			alt: "Alt",
			file: {
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
			veil: 0,
		},
		error: "404",
		title: "Página no encontrada",
		subtitle: "Lo siento, la página que buscas no existe.  ",
		button: {
			component: "Button",
			buttonText: "Ir a home",
			appearance: "secondary",
			size: "small",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
	},
	thumbnails: {},
};
