import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Basic Card",
	component: "BasicCard",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					mandatory: true,
					helptext: `
          Recomended sizes depending of layout used:
          Layout 1 (3 columns): 480x480px,
          Layout 2 (4 columns): 354x354px,
          Layout 3 (4 columns): 480x480px,
          Layout 4 (2 columns): 732x420px,
          Layout 5 (1 column): 1488x1488px.`,
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
				},
				{
					title: "Title",
					type: "TextField",
					key: "title",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Description",
					type: "TextField",
					key: "description",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "BasicCard",
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
		},
		veil: 0,
		title: "Lorem ipsum",
		description:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
		button: {
			component: "Button",
			buttonText: "Link",
			appearance: "link",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
	},

	thumbnails: {},
});
