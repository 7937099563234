export default {
	EVENTS: {
		title: "Events",
		dataPacks: ["EVENTS"],
		local: false,
		taxonomy: false,
		fromPage: true,
		translate: true,
		schema: {
			templates: ["EventDetail"],
			fields: [
				{
					title: "Event Date",
					type: "DateField",
					key: "eventDate",
					from: "eventDate",
					indexable: true,
				},
				{
					title: "Event Address",
					type: "TextField",
					humanReadable: true,
					key: "eventAddress",
					from: "eventAddress",
				},
				{
					title: "Event Hour",
					placeholder: "hh:mm",
					helptext: "hh:mm",
					type: "TextField",
					key: "eventHour",
					from: "eventHour",
				},
				{
					title: "Event Type",
					type: "RadioGroup",
					key: "eventType",
					from: "eventType",
				},
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "eventTitle",
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
					advanced: true,
					from: "eventTitle",
				},
				{
					title: "Description",
					type: "TextField",
					humanReadable: true,
					key: "description",
					from: "description",
				},
				{
					title: "Form Type",
					type: "ConditionalField",
					key: "conditionalForm",
					from: "conditionalForm",
				},
				{
					title: "Campaign ID",
					type: "TextField",
					key: "uECRM",
					from: "uECRM",
				},
				{
					title: "Programs List",
					type: "AsyncCheckGroup",
					source: "STUDY",
					key: "programsList",
					from: "programsList",
				},
				{
					title: "EventCard button text",
					type: "TextField",
					humanReadable: true,
					key: "eventCardText",
					from: "eventCardText",
				},
			],
			searchFrom: [
				"eventType",
				"eventTitle",
				"eventAddress",
				"eventDate",
				"eventCardText",
			],
		},
		clone: null,
		defaultValues: null,
	},
};
