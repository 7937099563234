import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Hero Card",
	component: "HeroCard",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Background Image",
					type: "ComponentContainer",
					whiteList: ["Image"],
					key: "backgroundImage",
					mandatory: true,
					helptext: "Recommended minimum image size: 2508x1030",
				},
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					mandatory: false,
					advanced: true,
					default: { tag: "h4", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Subtitle",
					type: "RichText",
					key: "subtitle",
					mandatory: false,
					hideable: true,
					helptext: "Maximum, 284 characters.",
					humanReadable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					mandatory: false,
				},
			],
		},
	],

	default: {
		component: "HeroCard",
		backgroundImage: {
			component: "Image",
			alt: "Alt",
			file: {
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
			veil: 0,
		},
		title: {
			content: "Title",
			tag: "h4",
		},
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		button: {
			component: "Button",
			buttonText: "Lorem Ipsum",
			appearance: "secondary",
			size: "big",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HeroCard_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HeroCard_x2.png`,
	},
});
