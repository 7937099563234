import { SiteContext } from "@contexts";
import { useContext } from "react";

function useRenderer() {
	const { renderer } = useContext(SiteContext);

	return {
		isSSG: renderer === "gatsby",
		isStorybook: renderer === "storybook",
		isEditor: renderer === "editor",
		isPreview: renderer === "preview",
		isGriddo: renderer === "preview" || renderer === "editor",
	};
}

export { useRenderer };
