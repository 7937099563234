import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Phone Number Element",
	component: "PhoneNumberElement",
	category: null,
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
				},
				{
					title: "Phone",
					type: "TextField",
					key: "phone",
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "PhoneNumberElement",
		theme: "default",
		title: "Title",
		phone: "000 000 000",
	},
});
