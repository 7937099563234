import { useEffect, useState, useCallback } from "react";
import { getWindow } from "ssr-window";

const window = getWindow();

export const useIsScrollPositionMoreThan = ({ yPos = 0 }) => {
	const isMore = useCallback(() => window.scrollY > yPos, [yPos]);

	const [is, setIs] = useState(isMore());
	const updateScrollPos = () => {
		setIs(isMore());
	};
	useEffect(() => {
		window.addEventListener("scroll", updateScrollPos);
		return () => window.removeEventListener("scroll", updateScrollPos);
	}, []);

	return is;
};
