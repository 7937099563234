import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Solid Card",
	component: "SolidCard",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					hideable: true,
				},
				{
					title: "Title",
					type: "TextField",
					key: "title",
					hideable: true,
					humanReadable: true,
				},
				{
					type: "Wysiwyg",
					key: "description",
					hideable: true,
					humanReadable: true,
				},
			],
		},
	],
	default: {
		component: "SolidCard",
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
		},
		title: "Lorem ipsum",
		description:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
	},
	// thumbnails: {
	//   '1x': `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/BasicCard`,
	//   '2x': `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/BasicCard@2x`,
	// },
});
