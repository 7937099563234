import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";

export default {
	EVENTS: {
		title: "Events",
		category: "ARTICLES_EVENTS",
		description: "It allows to use Events content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Events/events.png`,
	},
	STAFF: {
		title: "Staff",
		category: "PEOPLE",
		description: "It allows to use Staff content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Staff/staff.png`,
	},
	NEWS: {
		title: "News",
		category: "NEWS",
		description: "It allows to use News content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Staff/staff.png`,
	},
	LANDINGS: {
		title: "Landings",
		category: "LANDINGS",
		description: "It allows to use Landings content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Landings/Landings.png`,
	},
	TESTIMONIALS: {
		title: "Testimonials",
		category: "PEOPLE",
		description: "It allows to use Testimonials content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Testimonials/testimonials.png`,
	},
	COMPANIES: {
		title: "Companies",
		category: "COMPANIES",
		description: "It allows to use Companies content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Companies/Companies.png`,
	},
	AWARDS: {
		title: "Awards",
		category: "AWARDS",
		description: "It allows to use Awards content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Awards/awards.png`,
	},
	STUDY: {
		title: "Study",
		category: "STUDY",
		description: "It allows to use Study content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Study/study.png`,
	},
	STUDY_LANDING: {
		title: "Study landing",
		category: "STUDY_LANDING",
		description: "It allows to use Study Landing content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Study/study.png`,
	},
	STUDY_LANDING_TYPE: {
		title: "Study type landing",
		category: "STUDY_LANDING_TYPE",
		description: "It allows to use Study Type Landing content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Study/study.png`,
	},
	BLOG: {
		title: "Blog",
		category: "BLOG",
		description: "It allows to use Blog content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Staff/staff.png`,
	},
	JOB_OFFERS: {
		title: "Job Offers",
		category: "JOB_OFFERS",
		description: "It allows to use Alumni content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Alumni/alumni.png`, //@todo Añadir imagen
	},
	MODALS: {
		title: "Modals",
		category: "MODALS",
		description: "It allows to use Modals content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Modals/modals.png`,
	},
	QUIZZ: {
		title: "Quizz",
		category: "QUIZZ",
		description: "It allows to use Quizz content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Quizz/quizz.png`,
	},
	DISCOUNTS: {
		title: "Discounts",
		category: "DISCOUNTS",
		description: "It allows to use Discounts content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Discounts/discounts.png`,
	},
	WORD: {
		title: "Word",
		category: "WORD",
		description: "It allows to use Word content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Word/word.png`,
	},
};
