export default {
	QUIZZ: {
		title: "Quizz",
		dataPacks: ["QUIZZ"],
		local: false,
		taxonomy: false,
		fromPage: true,
		translate: true,
		schema: {
			templates: ["Quizz"],
			fields: [
				// QUESTIONS
				{
					title: "Intro",
					type: "RichText",
					key: "intro",
					from: "intro",
				},
				{
					title: "Start Button Text",
					type: "TextField",
					key: "buttonText",
					from: "buttonText",
				},
				{
					title: "Background",
					type: "ImageField",
					key: "background",
					from: "background",
				},
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					from: "image",
				},
				{
					type: "ArrayFieldGroup",
					title: "Quizz Questions",
					key: "questions",
					from: "questions",
				},
				{
					title: "Salesforce ID",
					type: "TextField",
					key: "salesforceId",
					from: "salesforceId",
				},
				{
					title: "Campaign ID",
					type: "TextField",
					key: "campaignId",
					from: "campaignId",
				},

				// FORM
				{
					title: "Form Title",
					type: "TextField",
					key: "formTitle",
					from: "formTitle",
				},
				{
					title: "Form Intro",
					type: "RichText",
					key: "formIntro",
					from: "formIntro",
				},

				// RESULTS
				{
					title: "Result Title",
					type: "TextField",
					key: "resultTitle",
					from: "resultTitle",
				},
				{
					title: "Result Intro",
					type: "RichText",
					key: "resultIntro",
					from: "resultIntro",
				},
				{
					title: "Disable results list",
					type: "RadioGroup",
					key: "disableResults",
					from: "disableResults",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
