const ShowDefaultMethodOfAdmission = {
	content: {
		title: "Show default selector Method of admission?",
		type: "ConditionalField",
		key: "showDefaultMethodOfAdmission",
		options: [
			{
				value: true,
				title: "Show Default selector",
				name: "defaultSelector",
			},
			{
				value: false,
				title: "Add custom value",
				name: "customValue",
			},
		],
		fields: [
			{
				title: "Custom value Method of admission",
				type: "TextField",
				key: "textCustomMethodOfAdmission",
				condition: false,
				helptext: "This text will be sent to the CRM as the default value.",
			},
		],
	},
	default: {
		showDefaultMethodOfAdmission: true,
		textCustomMethodOfAdmission: "",
	},
};

export default ShowDefaultMethodOfAdmission;
