import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Grouping Collection",
	component: "GroupingCollection",
	dataPacks: null,
	category: "interactive",
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
					advanced: true,
					hideable: true,
				},
				{
					title: "Grouping Element",
					type: "ComponentArray",
					whiteList: ["GroupingElement"],
					key: "elements",
					maxItems: null,
					mandatory: true,
				},
			],
		},
	],
	default: {
		component: "GroupingCollection",
		title: { content: "Program Title", tag: "h2" },
		elements: [
			{
				component: "GroupingElement",
				title: "Section Name",
				componentModules: [
					{
						component: "BasicContent",
						title: { content: "Basic content title text", tag: "h2" },
						subtitle: "Subtitle text",
						detail: "Detail text",
						image: {
							component: "Image",
							alt: "Alt",
							file: {
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							},
						},
						imageText: "Image detail",
						button: {
							component: "Button",
							buttonText: "Link",
							appearance: "link",
							size: "medium",
							url: {
								href: "",
								linkTo: null,
								newTab: false,
								noFollow: false,
							},
						},
						layout: "L001",
					},
				],
			},
		],
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/GroupingCollection_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/GroupingCollection_x2.png`,
	},
});
