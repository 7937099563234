const measurableHeaderSelector = ".griddo-header";

// Webfont css delay.
//
// When the browser load the page, this `onClientEntry` set the stylesheet rel
// attribute to "active" the webfonts css.
// This way when the browser load the html page, webfonst doesn't block the
// render.
const onClientEntry = () => {
	// webfonts
	const griddoWebfonts = document.getElementById("griddo-webfonts");
	if (griddoWebfonts) {
		griddoWebfonts.setAttribute("rel", "stylesheet");
	}
};

const onRouteUpdate = ({ location }) => {
	if (!window || !document || !location || !location.hash) {
		return;
	}

	const headerHeight =
		document.querySelector(measurableHeaderSelector)?.offsetHeight || 0;

	if (location.hash !== "") {
		window.scrollTo({
			left: 0,
			top: getOffset(location.hash).top - headerHeight,
			behavior: "smooth",
		});
	}
};

function getOffset(el) {
	const rect = document?.querySelector(el)?.getBoundingClientRect();

	return {
		left: rect?.left + window?.scrollX,
		top: rect?.top + window?.scrollY,
	};
}

export default {
	onRouteUpdate,
	onClientEntry,
};
