const ShowSelectorBachelorDegree = {
	content: {
		title: 'Show selector "Do you have a bachelor degree?"',
		type: "RadioGroup",
		key: "showSelectorBachelorDegree",
		options: [
			{
				value: true,
				title: "Yes",
				name: "yes",
			},
			{
				value: false,
				title: "No",
				name: "no",
			},
		],
	},
	default: {
		showSelectorBachelorDegree: false,
	},
};
export default ShowSelectorBachelorDegree;
